class Y {
    constructor(t) {
        this.erasePlaceholder = () => {
            if (!this.searchInput || this.searchInput.textContent == null)
                return;
            const e = this.searchInput.textContent;
            e.length > 0 ? (this.searchInput.textContent = e.slice(0, -1),
            this.typeErasePlaceholderEvent = setTimeout(this.erasePlaceholder, 100)) : (this.actualPlaceholderText = (this.actualPlaceholderText + 1) % this.placeholderTexts.length,
            setTimeout(this.typePlaceholder, 2e3))
        }
        ,
        this.typePlaceholder = () => {
            const e = this.placeholderTexts[this.actualPlaceholderText]
              , s = this.searchInput.textContent;
            s != null && (s.length < e.length ? (this.searchInput.textContent = e.slice(0, s.length + 1),
            this.typeErasePlaceholderEvent = setTimeout(this.typePlaceholder, 100)) : (clearTimeout(this.typeErasePlaceholderEvent),
            setTimeout(this.erasePlaceholder, 2e3)))
        }
        ,
        this.searchElement = t,
        this.searchInput = this.searchElement.querySelector("[data-search]"),
        this.placeholderBlocks = this.searchElement.querySelector("[data-search-placeholders]"),
        this.placeholderTexts = this.getPlaceholderTexts(),
        this.addPlaceholdersEffect(),
        this.actualPlaceholderText = 0
    }
    getPlaceholderTexts() {
        return !this.placeholderBlocks || !this.placeholderBlocks.textContent ? [] : JSON.parse(this.placeholderBlocks.textContent).general.search
    }
    addPlaceholdersEffect() {
        this.placeholderTexts.length && setTimeout(this.erasePlaceholder, 2e3)
    }
}
class K extends HTMLElement {
    constructor() {
        super(),
        this.searchs = [],
        this.querySelectorAll("[data-header-search-block]").forEach(e => {
            var s;
            (s = this.searchs) == null || s.push(new Y(e))
        }
        ),
        this.setSubmenu(),
        this.menu = document.querySelector(".section-header__innher__menu__desktop"),
        this.menuIcon = this.querySelector("[data-menu-icon]"),
        this.overlayMenu = this.querySelector(".section-header__inner__menu__overlay"),
        this.closeMenuIcons = this.querySelectorAll("[data-icon-close-menu]"),
        this.menuMobile(),
        this.animationScrollMenu()
    }
    animationScrollMenu() {
        let t = window.scrollY;
        window.addEventListener("scroll", () => {
            if (window.innerWidth > 1024) {
                if (window.scrollY < 200)
                    return;
                const e = window.scrollY;
                e > t ? this.menu.setAttribute("hidden", "") : this.menu.removeAttribute("hidden"),
                t = e
            } else
                this.menu.removeAttribute("hidden")
        }
        )
    }
    menuMobile() {
        document.addEventListener("click", t => {
            var e;
            this.menu.classList.contains("section-header__innher__menu__desktop--active") && t.target != this.menu && t.target != this.menuIcon && !this.menu.contains(t.target) && !this.menuIcon.contains(t.target) && (this.menu.classList.remove("section-header__innher__menu__desktop--active"),
            this.overlayMenu.classList.remove("section-header__inner__menu__overlay--active"),
            (e = document.querySelector("body")) == null || e.classList.remove("no-scroll"))
        }
        ),
        this.menuIcon.addEventListener("click", t => {
            var e;
            this.menu.classList.toggle("section-header__innher__menu__desktop--active"),
            this.overlayMenu.classList.toggle("section-header__inner__menu__overlay--active"),
            (e = document.querySelector("body")) == null || e.classList.toggle("no-scroll")
        }
        ),
        this.closeMenuIcons.forEach(t => {
            t.addEventListener("click", e => {
                var s;
                this.menu.classList.remove("section-header__innher__menu__desktop--active"),
                this.overlayMenu.classList.remove("section-header__inner__menu__overlay--active"),
                (s = document.querySelector("body")) == null || s.classList.remove("no-scroll")
            }
            )
        }
        )
    }
    setSubmenu() {
        document.querySelectorAll(".section-header__inner__menu__item").forEach(e => {
            const s = e.parentElement;
            s.addEventListener("click", i => {
                var l;
                if (window.innerWidth > 1024)
                    return;
                const r = "icon-mobile"
                  , n = i.target;
                if (!n.classList.contains(r) && !((l = n.parentElement) != null && l.classList.contains(r)))
                    return;
                i.preventDefault();
                const o = s.querySelector(".section-header__inner__menu__sub-menu");
                o && o.classList.toggle("section-header__inner__menu__item__submenu--active")
            }
            ),
            s.addEventListener("mouseover", i => {
                if (window.innerWidth < 1024)
                    return;
                const r = s.querySelector(".section-header__inner__menu__sub-menu");
                r && r.classList.add("section-header__inner__menu__item__submenu--active")
            }
            ),
            s.addEventListener("mouseout", i => {
                if (window.innerWidth < 1024)
                    return;
                const r = s.querySelector(".section-header__inner__menu__sub-menu");
                r && r.classList.remove("section-header__inner__menu__item__submenu--active")
            }
            )
        }
        )
    }
}
class X extends HTMLElement {
    constructor() {
        super()
    }
}
class j extends HTMLElement {
    constructor(t, e) {
        super(),
        this.fetchData = new y,
        this.render(t, e)
    }
    render(t, e) {
        const s = document.createElement("img");
        s.src = t.featured_image,
        s.alt = t.title;
        const i = document.createElement("div")
          , r = document.createElement("a");
        r.textContent = t.title,
        r.href = t.url,
        r.classList.add("cart-drawer__related-product-title");
        const n = document.createElement("p");
        let o = t.price;
        t.variants.length > 0 && (o = t.variants[0].price),
        n.textContent = `$${(o / 100).toFixed(2)}`,
        n.classList.add("cart-drawer__related-product-price");
        const l = document.createElement("select");
        l.classList.add("cart-drawer__related-product-variants"),
        l.addEventListener("change", u => {
            const d = Array.from(l.querySelectorAll("option")).find(m => m.selected)
              , p = d.dataset.price ? `$${(parseInt(d.dataset.price) / 100).toFixed(2)}` : "";
            n.textContent = p
        }
        ),
        t.variants.forEach(u => {
            const d = document.createElement("option");
            d.value = u.id.toString(),
            d.dataset.price = u.price.toString(),
            d.textContent = u.title,
            l.appendChild(d)
        }
        );
        const c = document.createElement("button");
        c.textContent = e || "Add to cart",
        c.style.cursor = "pointer",
        c.classList.add("cart-drawer__related-product-add-to-cart"),
        c.addEventListener("click", async () => {
            this.fetchData.addToCart(t.id.toString(), l.value)
        }
        ),
        i.appendChild(r),
        i.appendChild(n),
        t.variants.length > 0 && /Default/i.test(t.variants[0].title) && (l.style.opacity = "0"),
        i.appendChild(l),
        i.appendChild(c),
        this.appendChild(s),
        this.appendChild(i)
    }
}
function Z(a) {
    const {slidesToShow: t, slidesToScroll: e, loop: s} = a.config
      , {isStatic: i, totalSlides: r} = a
      , n = []
      , o = r - 1;
    for (let l = 0; l < r; l += e) {
        const c = l + t - 1;
        if (c > o)
            if (s) {
                const d = c - r;
                n.push([l, d])
            } else {
                const d = o - t + 1
                  , p = n.length - 1;
                (n.length === 0 || n.length > 0 && n[p][0] !== d) && n.push([d, o]);
                break
            }
        else
            n.push([l, c]);
        if (i)
            break
    }
    return n
}
function tt(a) {
    const {totalSlides: t} = a
      , {loop: e} = a.config
      , s = Z(a)
      , i = []
      , r = s.length - 1;
    for (let n = 0; n < s.length; n++) {
        let o, l;
        e ? (o = n === r ? 0 : n + 1,
        l = n === 0 ? r : n - 1) : (o = n === r ? r : n + 1,
        l = n === 0 ? 0 : n - 1);
        const c = s[n][0]
          , u = s[o][0]
          , d = s[l][0];
        let p = u - c;
        u < c && (p += t);
        let m = c - d;
        d > c && (m += t),
        i.push({
            page: s[n],
            next: {
                stateIndex: o,
                moveSlides: p
            },
            prev: {
                stateIndex: l,
                moveSlides: m
            }
        })
    }
    return i
}
const I = "start"
  , H = "end";
function et(a) {
    const {slidesToScroll: t, slidesToShow: e} = a.config
      , {totalSlides: s, config: i} = a;
    if (s < e && (i.slidesToShow = s),
    !(s <= e) && (t > e && (i.slidesToScroll = e),
    s < t + e)) {
        const r = s - e;
        i.slidesToScroll = r
    }
}
class st {
    constructor(t, e) {
        this.config = e,
        this.totalSlides = t,
        this.isTransitioning = !1,
        G(this, t, e)
    }
    next(t=1) {
        if (this.isTransitioning || this.isStatic)
            return;
        const {stateIndex: e} = this;
        let s = 0
          , i = e;
        for (let r = 0; r < t; r++) {
            const n = this.states[i];
            s += n.next.moveSlides,
            i = n.next.stateIndex
        }
        if (i !== e)
            return this.stateIndex = i,
            [e, s]
    }
    prev(t=1) {
        if (this.isTransitioning || this.isStatic)
            return;
        const {stateIndex: e} = this;
        let s = 0
          , i = e;
        for (let r = 0; r < t; r++) {
            const n = this.states[i];
            s += n.prev.moveSlides,
            i = n.prev.stateIndex
        }
        if (i !== e)
            return this.stateIndex = i,
            [e, s]
    }
}
function G(a, t, e) {
    a.stateIndex = 0,
    et(a),
    a.isStatic = t <= e.slidesToShow,
    a.states = tt(a)
}
function it(a, t) {
    const e = requestAnimationFrame;
    if (!a.config.loop)
        V(a);
    else {
        O(a),
        a.offset = -1 * t,
        _(a),
        nt(a, t);
        const s = () => {
            e( () => {
                B(a),
                e( () => {
                    a.offset = 0,
                    _(a),
                    D(a)
                }
                )
            }
            )
        }
        ;
        a.isDragging ? C() ? a.track.addEventListener("touchend", s, {
            once: !0
        }) : a.track.addEventListener("pointerup", s, {
            once: !0
        }) : e(s)
    }
}
function rt(a, t) {
    const e = requestAnimationFrame;
    a.config.loop ? (a.offset = -1 * t,
    _(a),
    setTimeout( () => {
        at(a, t),
        O(a),
        a.offset = 0,
        _(a),
        e( () => {
            e( () => {
                B(a),
                D(a)
            }
            )
        }
        )
    }
    , a.config.transitionDuration)) : V(a)
}
function D(a) {
    if (a.onSlideCbs) {
        const t = a.states[a.stateIndex]
          , [e,s] = t.page;
        a.onSlideCbs.forEach(i => i(a.stateIndex, e, s))
    }
}
function V(a) {
    a.offset = -1 * a.states[a.stateIndex].page[0],
    _(a),
    D(a)
}
function nt(a, t) {
    const e = a.slides.length;
    for (let s = 0; s < t; s++) {
        const i = a.slides[e - 1];
        a.track.prepend(i)
    }
}
function at(a, t) {
    for (let e = 0; e < t; e++)
        a.track.append(a.slides[0])
}
function _(a) {
    const {track: t, offset: e, dragged: s} = a;
    e === 0 ? t.style.transform = `translate3d(${s}px,0px,0px)` : t.style.transform = `translate3d(  calc( ${s}px + ${e} * (var(--slide-width) + ${a.config.slideGap})),0px,0px)`
}
function B(a) {
    a.track.style.transitionDuration = `${a.config.transitionDuration}ms`
}
function O(a) {
    a.track.style.transitionDuration = "0ms"
}
const M = 10
  , C = () => "ontouchstart"in window;
function R(a) {
    const t = this
      , e = t.slider;
    e.isTransitioning || (e.dragged = 0,
    t.isScrolled = !1,
    t.startMouseClientX = "touches"in a ? a.touches[0].clientX : a.clientX,
    "touches"in a || (a.target || t).setPointerCapture(a.pointerId),
    O(e),
    U(t, "addEventListener"))
}
function F(a) {
    const t = this
      , e = "touches"in a ? a.touches[0].clientX : a.clientX
      , s = t.slider.dragged = e - t.startMouseClientX
      , i = Math.abs(s);
    i > 5 && (t.slider.isDragging = !0),
    i > 15 && a.preventDefault(),
    t.slider.dragged = s,
    _(t.slider),
    !t.isScrolled && t.slider.config.loop && s > M && (t.isScrolled = !0,
    t.slider.prev())
}
function k() {
    const a = this
      , t = a.slider.dragged;
    a.slider.isDragging = !1,
    U(a, "removeEventListener"),
    a.slider.dragged = 0,
    _(a.slider),
    B(a.slider),
    a.isScrolled || (t < -1 * M ? a.slider.next() : t > M && a.slider.prev())
}
const ot = a => a.preventDefault();
function lt(a) {
    const t = a.track;
    t.slider = a;
    const e = C() ? "touchstart" : "pointerdown";
    t.addEventListener(e, R),
    t.addEventListener("click", s => {
        (a.isTransitioning || a.isDragging) && (s.preventDefault(),
        s.stopImmediatePropagation(),
        s.stopPropagation())
    }
    , {
        capture: !0
    }),
    t.addEventListener("dragstart", ot)
}
function U(a, t) {
    a[t]("contextmenu", k),
    C() ? (a[t]("touchend", k),
    a[t]("touchmove", F)) : (a[t]("pointerup", k),
    a[t]("pointermove", F))
}
function ct(a) {
    const t = a.config;
    if (!t.enableAutoplay)
        return;
    const e = t.autoplayDirection === "to left" ? "next" : "prev";
    a.autoplayTimer = setInterval( () => {
        a[e]()
    }
    , t.autoplayInterval),
    t.stopAutoplayOnInteraction && a.el.addEventListener(C() ? "touchstart" : "mousedown", () => {
        clearInterval(a.autoplayTimer)
    }
    , {
        once: !0
    })
}
const A = {
    slideGap: "20px",
    slidesToScroll: 1,
    slidesToShow: 1,
    loop: !0,
    enableAutoplay: !1,
    stopAutoplayOnInteraction: !0,
    autoplayInterval: 3e3,
    autoplayDirection: "to left",
    enablePagination: !0,
    transitionDuration: 300,
    transitionTimingFunction: "ease",
    draggable: !0
};
function $(a) {
    const t = {
        ...A
    };
    for (const e in a)
        if (window.matchMedia(e).matches) {
            const s = a[e];
            for (const i in s)
                t[i] = s[i]
        }
    return t
}
function dt(a) {
    const t = a.el.querySelector(".blaze-prev")
      , e = a.el.querySelector(".blaze-next");
    t && (t.onclick = () => {
        a.prev()
    }
    ),
    e && (e.onclick = () => {
        a.next()
    }
    )
}
function ut(a) {
    if (!a.config.enablePagination || a.isStatic)
        return;
    const t = a.el.querySelector(".blaze-pagination");
    if (!t)
        return;
    a.paginationButtons = [];
    const e = a.states.length;
    for (let s = 0; s < e; s++) {
        const i = document.createElement("button");
        a.paginationButtons.push(i),
        i.textContent = 1 + s + "",
        i.ariaLabel = `${s + 1} of ${e}`,
        t.append(i),
        i.slider = a,
        i.index = s,
        i.onclick = ht
    }
    a.paginationButtons[0].classList.add("active")
}
function ht() {
    const a = this.index
      , t = this.slider
      , e = t.stateIndex
      , s = t.config.loop
      , i = Math.abs(a - e)
      , r = t.states.length - i
      , o = i > t.states.length / 2 && s;
    a > e ? o ? t.prev(r) : t.next(i) : o ? t.next(r) : t.prev(i)
}
function q(a, t=a.config.transitionDuration) {
    a.isTransitioning = !0,
    setTimeout( () => {
        a.isTransitioning = !1
    }
    , t)
}
class h extends st {
    constructor(t, e) {
        const s = t.querySelector(".blaze-track")
          , i = s.children
          , r = e ? $(e) : {
            ...A
        };
        super(i.length, r),
        this.config = r,
        this.el = t,
        this.track = s,
        this.slides = i,
        this.offset = 0,
        this.dragged = 0,
        this.isDragging = !1,
        this.el.blazeSlider = this,
        this.passedConfig = e;
        const n = this;
        s.slider = n,
        z(r, n);
        let o = !1
          , l = 0;
        window.addEventListener("resize", () => {
            if (l === 0) {
                l = window.innerWidth;
                return
            }
            const c = window.innerWidth;
            l !== c && (l = c,
            o || (o = !0,
            setTimeout( () => {
                n.refresh(),
                o = !1
            }
            , 200)))
        }
        )
    }
    next(t) {
        if (this.isTransitioning)
            return;
        const e = super.next(t);
        if (!e) {
            q(this);
            return
        }
        const [s,i] = e;
        N(this, s),
        q(this),
        rt(this, i)
    }
    prev(t) {
        if (this.isTransitioning)
            return;
        const e = super.prev(t);
        if (!e) {
            q(this);
            return
        }
        const [s,i] = e;
        N(this, s),
        q(this),
        it(this, i)
    }
    stopAutoplay() {
        clearInterval(this.autoplayTimer)
    }
    destroy() {
        var t;
        this.track.removeEventListener(C() ? "touchstart" : "pointerdown", R),
        this.stopAutoplay(),
        (t = this.paginationButtons) == null || t.forEach(e => e.remove()),
        this.el.classList.remove("static"),
        this.el.classList.remove(I)
    }
    refresh() {
        const t = this.passedConfig ? $(this.passedConfig) : {
            ...A
        };
        this.destroy(),
        z(t, this)
    }
    onSlide(t) {
        return this.onSlideCbs || (this.onSlideCbs = new Set),
        this.onSlideCbs.add(t),
        () => this.onSlideCbs.delete(t)
    }
}
function N(a, t) {
    const e = a.el.classList
      , s = a.stateIndex
      , i = a.paginationButtons;
    a.config.loop || (s === 0 ? e.add(I) : e.remove(I),
    s === a.states.length - 1 ? e.add(H) : e.remove(H)),
    i && a.config.enablePagination && (i[t].classList.remove("active"),
    i[s].classList.add("active"))
}
function z(a, t) {
    const e = t.track;
    t.slides = e.children,
    t.offset = 0,
    t.config = a,
    G(t, t.totalSlides, a),
    a.loop || t.el.classList.add(I),
    a.enableAutoplay && !a.loop && (a.enableAutoplay = !1),
    e.style.transitionProperty = "transform",
    e.style.transitionTimingFunction = t.config.transitionTimingFunction,
    e.style.transitionDuration = `${t.config.transitionDuration}ms`;
    const {slidesToShow: s, slideGap: i} = t.config;
    t.el.style.setProperty("--slides-to-show", s + ""),
    t.el.style.setProperty("--slide-gap", i),
    t.isStatic ? t.el.classList.add("static") : a.draggable && lt(t),
    ut(t),
    ct(t),
    dt(t),
    _(t)
}
class W {
    constructor(t, e, s, i) {
        this.sliderElement = document.createElement("div"),
        this.slider = null,
        this.listElement = document.createElement("ul"),
        this.listElement.classList.add("blaze-track"),
        this.sliderElement.classList.add("blaze-slider"),
        this.sliderElement.innerHTML = `
      <div class="blaze-container"></div>
      <button class="blaze-prev related-products-slider-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 31" fill="none"><path d="M0 15.5L13.3333 31L16 27.9L5.33333 15.5L16 3.1L13.3333 0L0 15.5Z" fill="black"/></svg>
      </button>
      <button class="blaze-next related-products-slider-arrow">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 31" fill="none"><path d="M16 15.5L2.66667 31L0 27.9L10.6667 15.5L0 3.1L2.66667 0L16 15.5Z" fill="black"/></svg>
      </button>
    `,
        this.listElement.classList.add("scrollbar-hidden"),
        this.addToCartTranslation = e,
        this.container = s,
        this.breakpoint = i,
        this.currentViewPort = document.documentElement.clientWidth,
        this.setup(),
        this.fetchProductList(t)
    }
    setup() {
        var t;
        (t = this.sliderElement.querySelector(".blaze-container")) == null || t.appendChild(this.listElement),
        this.container.appendChild(this.sliderElement),
        this.setupSlider()
    }
    async fetchProductList(t) {
        try {
            if (!t)
                return;
            const e = await fetch(`/recommendations/products.json?product_id=${t}&limit=7&intent=related`);
            if (!e.ok)
                throw new Error("Recommended products not found!");
            const s = await e.json()
              , {products: i} = s;
            this.listElement.innerHTML = "",
            this.appendToList(i, this.listElement)
        } catch (e) {
            console.error(e)
        }
    }
    appendToList(t, e) {
        return t.forEach(s => {
            if (s.vendor == "DO NOT PICK" || s.tags.includes("hidden") || s.tags.includes("hidden_prod_grid"))
                return;
            const i = document.createElement("li");
            i.classList.add("blaze-slide"),
            i.appendChild(new j(s,this.addToCartTranslation)),
            e.appendChild(i)
        }
        ),
        this.setupSlider(),
        e
    }
    setupSlider() {
        this.slider && this.slider.destroy(),
        this.slider = new h(this.sliderElement,{
            all: {
                slidesToShow: 1,
                draggable: !0,
                loop: !1
            },
            "(min-width: 768px)": {
                slidesToShow: 1,
                draggable: !1,
                loop: !1
            }
        })
    }
}
class f extends HTMLElement {
    constructor() {
        var t;
        super(),
        f.cart = this,
        this.translations = JSON.parse(((t = this.querySelector("[data-translations]")) == null ? void 0 : t.textContent) || "{}"),
        this.relatedProductsLists = [],
        this.cartItemsList = this.querySelector("ul[data-cart-product-list]"),
        this.fetchData = new y,
        this.freeShippingElement = this.querySelector("free-shipping-cart-drawer"),
        this.productGiftWithPurchase = this.querySelector("gift-with-purchase-cart-drawer"),
        window.location.pathname !== "/cart" && this.setup()
    }
    static updateCart(t, e=!0) {
        var n;
        const s = document.querySelector("[data-cart-empty]")
          , i = document.querySelector("[data-cart-with-item]")
          , r = document.querySelector("[data-cart-count]");
        if (t.item_count === 0)
            s.style.display = "flex",
            i.style.display = "none";
        else {
            s.style.display = "none",
            i.style.display = "flex",
            r.innerHTML = t.item_count.toString();
            const o = t.total_price / 100;
            (n = f.cart) != null && n.freeShippingElement && f.cart.freeShippingElement.updateProgressBar(o),
            f.cart.productGiftWithPurchase && f.cart.productGiftWithPurchase.updateMessage(o)
        }
        f.cart.rerender(t)
    }
    rerender(t) {
        const e = this.querySelector("[data-cart-total-price]")
          , s = this.querySelector("[data-cart-purchase-price]")
          , i = this.querySelector("[data-cart-header] p")
          , {items: r} = t;
        this.renderCartItems(r),
        e.innerHTML = `$${(t.total_price / 100).toFixed(2)}`,
        s.innerHTML = `$${(t.total_price / 100).toFixed(2)}`,
        i.innerHTML = `Shopping cart (${t.item_count})`;
        const n = r.filter(o => o.product_type !== "Free Sample" && o.product_type !== "Gift Wrap");
        if (n.length > 0)
            if (this.relatedProductsLists.length === 0) {
                const o = r.find(l => l.product_type !== "Free Sample" && l.product_type !== "Gift Wrap");
                if (!o)
                    return;
                this.querySelectorAll("[data-cart-related-products]").forEach(l => {
                    const c = new W(o.product_id.toString(),this.translations.add_to_cart,l,768);
                    c.fetchProductList(o.product_id.toString()),
                    this.relatedProductsLists.push(c)
                }
                )
            } else
                this.relatedProductsLists.forEach(async o => {
                    var l;
                    await o.fetchProductList(n[0].product_id.toString()),
                    (l = o.slider) == null || l.refresh()
                }
                );
        this.handleEmptyCart(t.item_count)
    }
    async setup() {
        this.setEventListeners([".section-header__open-cart-button"], [".close-cart-btn", ".cart-drawer__before"]);
        const {items: t} = await this.fetchData.getCart();
        this.handleEmptyCart(t.length),
        this.renderCartItems(t);
        const e = t.find(s => s.product_type !== "Free Sample" && s.product_type !== "Gift Wrap");
        e && this.querySelectorAll("[data-cart-related-products]").forEach(s => {
            const i = new W(e.product_id.toString(),this.translations.add_to_cart,s,768);
            i.fetchProductList(e.product_id.toString()),
            this.relatedProductsLists.push(i)
        }
        )
    }
    static async openCart() {
        const {items: t} = await f.cart.fetchData.getCart();
        if (t.every(i => i.product_type === "Free Sample") && t.length > 0)
            return;
        f.cart.classList.add("open");
        const s = await w.makeCartObject();
        w.viewCart(s, !0)
    }
    static closeCart() {
        f.cart.classList.remove("open");
        const t = f.cart.querySelector("gift-wrap-modal");
        t && (t.style.display = "none")
    }
    setEventListeners(t, e) {
        t.forEach(s => {
            const i = document.querySelector(s);
            i.addEventListener("click", () => {
                f.openCart()
            }
            ),
            i.style.cursor = "pointer"
        }
        ),
        e.forEach(s => {
            const i = document.querySelector(s);
            i.addEventListener("click", () => {
                f.closeCart()
            }
            ),
            i.style.cursor = "pointer"
        }
        )
    }
    handleEmptyCart(t) {
        const e = this.querySelector("[data-cart-empty-message]")
          , s = Array.from(this.querySelectorAll("[data-cart-empty-hidden]"));
        t === 0 ? (e.style.display = "flex",
        s.forEach(i => {
            i.style.display = "none"
        }
        ),
        this.classList.add("empty")) : (e.style.display = "none",
        s.forEach(i => {
            i.style.display = ""
        }
        ),
        this.classList.remove("empty"))
    }
    renderCartItems(t) {
        const e = this.cartItemsList.childNodes;
        for (let s = e.length - 1; s >= 0; s--)
            e[s].nodeName === "LI" && this.cartItemsList.removeChild(e[s]);
        t.forEach(s => {
            const i = document.createElement("li")
              , r = document.createElement("cart-product-item");
            r.setAttribute("data-product-id", s.product_id.toString()),
            r.setAttribute("data-cart-product-variant-id", s.variant_id.toString()),
            r.setAttribute("data-product-price", s.price.toString()),
            r.product = s,
            r.removeTranslation = this.translations.remove,
            r.render(s),
            i.appendChild(r),
            this.cartItemsList.insertBefore(i, this.cartItemsList.firstChild)
        }
        )
    }
}
class Q {
    constructor() {
        this.toastElement = null
    }
    showToast(t, e="info", s=3e3) {
        this.toastElement && this.hideToast(),
        this.toastElement = document.createElement("div"),
        this.toastElement.className = `product-card-toast product-card-toast-${e}`,
        this.toastElement.textContent = t,
        document.body.appendChild(this.toastElement),
        this.toastElement.offsetHeight,
        this.toastElement.classList.add("visible"),
        setTimeout( () => {
            this.hideToast()
        }
        , s)
    }
    hideToast() {
        this.toastElement && (this.toastElement.classList.remove("visible"),
        setTimeout( () => {
            this.toastElement && (document.body.removeChild(this.toastElement),
            this.toastElement = null)
        }
        , 300))
    }
}
class E extends HTMLElement {
    constructor() {
        var e, s;
        super(),
        E.cartPage = this,
        this.cartTable = this.querySelector(".cart-table"),
        this.loading = this.querySelector(".loading__container"),
        this.translations = JSON.parse(((e = this.querySelector("[data-translations]")) == null ? void 0 : e.textContent) || "{}"),
        this.cartItemsList = this.querySelector("[data-cart-product-list]"),
        this.fetchData = new y,
        this.freeSamples = [],
        this.regularProducts = [],
        this.cartEmpty = this.querySelector("[data-page-cart-empty-hidden]"),
        this.cartNotEmpty = this.querySelectorAll("[data-page-cart]"),
        this.giftWithPurchaseInCart = !1,
        this.cartPageElement = document.querySelector("cart-page"),
        this.orderSummary = document.querySelector("#order-summary-total"),
        this.toast = new Q,
        this.giftWithPurchaseCoupon = this.getAttribute("data-gift-with-purchase-coupon"),
        this.giftWithPurchaseValue = this.getAttribute("data-gift-with-purchase-value"),
        this.giftWithPurchaseProductId = this.getAttribute("data-gift-with-purchase-product-id"),
        this.hasGiftWithPurchase = !1,
        this.insideVerifyGWP = !1,
        this.setupEmptyCart(),
        this.setupProductButtons();
        const t = (s = document.querySelector("#order-summary-total .order-summary-total_value")) == null ? void 0 : s.textContent;
        t && this.verifyGiftWithPurchase(t)
    }
    limitOverQuantity() {
        this.toast.showToast("You cannot add more than one item to the cart", "error"),
        this.setLoading(!1)
    }
    setupQuantity(t) {
        t == null || t.addEventListener("submit", async e => {
            e.preventDefault(),
            e.stopPropagation();
            const s = new FormData(t)
              , i = Number(s.get("quantity"))
              , r = s.get("id")
              , n = s.get("variant-id")
              , o = s.get("product_type");
            if (o === "Free Sample" && i > 1)
                return this.limitOverQuantity();
            if (o != "Free Sample" && i > 10)
                return this.limitOverQuantity();
            n == window.giftWithPurchaseId && i == 0 && localStorage.setItem("gwpRemoved", "true"),
            this.setLoading(!0);
            try {
                const l = await this.fetchData.updateCart(r, i);
                await this.verifyEmptyCart(l).then( () => this.verifyItemsCart(l).then( () => this.refreshCart())).then( () => this.setupProductButtons())
            } catch (l) {
                console.error("Erro ao atualizar o carrinho:", l)
            } finally {
                this.setLoading(!1)
            }
        }
        )
    }
    setLoading(t) {
        t ? this.loading.classList.add("loading__active") : this.loading.classList.remove("loading__active")
    }
    setupProductButtons() {
        var e;
        const t = (e = this.cartPageElement) == null ? void 0 : e.querySelectorAll(".cart-quantity__plus-form, .cart-quantity__minus-form, .cart-item__remove-form");
        t == null || t.forEach(s => this.setupQuantity(s))
    }
    async setupEmptyCart() {
        try {
            const t = await this.fetchData.getCart();
            await this.verifyEmptyCart(t)
        } catch (t) {
            console.error("Erro ao configurar o carrinho vazio:", t)
        }
    }
    async verifyItemsCart(t) {
        try {
            await this.limitAndRemoveSamples(t.items)
        } catch (e) {
            console.error("Erro ao verificar os itens do carrinho:", e)
        }
    }
    async limitAndRemoveSamples(t) {
        t.length !== 0 && (this.freeSamples = t.filter(e => e.product_type === "Free Sample"),
        this.freeSamples.length === t.length && await this.removeSamples())
    }
    async removeSamples() {
        this.setLoading(!0);
        try {
            for (const t of this.freeSamples)
                await this.fetchData.updateCart(t.id.toString(), 0);
            await this.refreshCart()
        } catch (t) {
            console.error("Erro ao remover as amostras gratuitas:", t)
        } finally {
            this.setLoading(!1)
        }
    }
    async refreshCart(t=!1) {
        var e;
        this.setLoading(!0);
        try {
            const i = (await this.fetchData.getCart()).items.find(m => m.variant_id.toString() === window.giftWithPurchaseId);
            i && i.line_price > 0 && await this.fetchData.updateCart(window.giftWithPurchaseId, 0, !0);
            const n = await (await fetch("/cart")).text()
              , o = new DOMParser().parseFromString(n, "text/html")
              , l = o.querySelector(".cart-table")
              , c = o.querySelector("#order-summary-total")
              , u = o.querySelector(".gift-with-purchase")
              , d = (e = o.querySelector("#order-summary-total .order-summary-total_value")) == null ? void 0 : e.textContent;
            if (this.cartTable && l && (this.cartTable.innerHTML = l.innerHTML),
            this.orderSummary && c && (this.orderSummary.innerHTML = c.innerHTML),
            u) {
                const m = document.querySelector(".gift-with-purchase");
                m && (m.innerHTML = u.innerHTML)
            }
            this.setupProductButtons(),
            document.querySelectorAll("free-samples").forEach(async m => {
                await m.updateFreeSamplesDisplay()
            }
            ),
            !t && d && await this.verifyGiftWithPurchase(d)
        } catch (s) {
            console.error("Erro ao atualizar o carrinho após remoção:", s)
        } finally {
            this.setLoading(!1)
        }
    }
    async verifyEmptyCart(t) {
        var s, i;
        const e = t.items.filter(r => r.product_type === "Free Sample");
        t.items.length === 0 || e.length === t.items.length ? ((s = this.cartEmpty) == null || s.classList.remove("cart-empty-hidden"),
        this.cartNotEmpty.forEach(r => r.classList.add("cart-empty-hidden"))) : ((i = this.cartEmpty) == null || i.classList.add("cart-empty-hidden"),
        this.cartNotEmpty.forEach(r => r.classList.remove("cart-empty-hidden")))
    }
    async verifyGiftWithPurchase(t) {
        var l;
        if (!window.gwpCampaign || this.insideVerifyGWP)
            return;
        this.insideVerifyGWP = !0;
        let e = parseFloat(t == null ? void 0 : t.replace(/[^0-9.]/g, ""))
          , s = !1;
        const i = parseFloat(this.giftWithPurchaseValue)
          , r = document.querySelector(`[data-product-cart-id="${this.giftWithPurchaseProductId}"]`);
        this.giftWithPurchaseInCart = !!r;
        const n = (l = r == null ? void 0 : r.querySelector(".cart-table-total")) == null ? void 0 : l.textContent;
        n && parseFloat(n == null ? void 0 : n.replace(/[^0-9.]/g, "")) > 0 && (s = !0);
        const o = localStorage.getItem("gwpRemoved") === "true";
        if (e >= i && !s && !this.giftWithPurchaseInCart && !o) {
            if (r)
                return;
            fetch("/cart/add.js", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    items: [{
                        id: this.giftWithPurchaseProductId,
                        quantity: 1
                    }]
                })
            }).then(async () => {
                document.cookie = `discount_code=${this.giftWithPurchaseCoupon}; path=/`,
                await fetch(`/discount/${this.giftWithPurchaseCoupon}`).then(function() {
                    return fetch("/cart/update.js", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            updates: {}
                        })
                    })
                }).then(async () => {
                    await this.refreshCart(!0),
                    this.insideVerifyGWP = !1
                }
                )
            }
            )
        } else
            e < i && localStorage.setItem("gwpRemoved", "false"),
            this.insideVerifyGWP = !1
    }
}
class y {
    constructor() {
        this.getCart = async () => {
            const e = await (await fetch("/cart.js")).json()
              , s = {
                token: e.token,
                note: e.note,
                attributes: e.attributes,
                original_total_price: e.original_total_price,
                total_price: e.total_price,
                total_discount: e.total_discount,
                total_weight: e.total_weight,
                item_count: e.item_count,
                items: [],
                requires_shipping: e.requires_shipping,
                currency: e.currency,
                items_subtotal_price: e.items_subtotal_price,
                cart_level_discount_applications: e.cart_level_discount_applications
            }
              , i = await this.getUpdatedDrawerItems();
            for (const r of e.items) {
                const n = i.get(r.id) ?? 0
                  , o = {
                    id: r.id,
                    properties: r.properties,
                    quantity: r.quantity,
                    variant_id: r.variant_id,
                    key: r.key,
                    title: r.title,
                    price: r.price,
                    compare_at_price: n,
                    original_price: r.original_price,
                    discounted_price: r.discounted_price,
                    line_price: r.line_price,
                    original_line_price: r.original_line_price,
                    total_discount: r.total_discount,
                    discounts: r.discounts,
                    sku: r.sku,
                    grams: r.grams,
                    vendor: r.vendor,
                    taxable: r.taxable,
                    product_id: r.product_id,
                    gift_card: r.gift_card,
                    url: r.url,
                    featured_image: r.featured_image,
                    image: r.image,
                    handle: r.handle,
                    requires_shipping: r.requires_shipping,
                    product_type: r.product_type,
                    product_title: r.product_title,
                    product_description: r.product_description,
                    variant_title: r.variant_title,
                    variant_options: r.variant_options,
                    options_with_values: r.options_with_values
                };
                s.items.push(o)
            }
            return s
        }
        ,
        this.getProducts = async () => (await (await fetch("/products.json")).json()).products.map(i => ({
            id: i.id,
            title: i.title,
            handle: i.handle,
            body_html: i.body_html,
            published_at: i.published_at,
            created_at: i.created_at,
            updated_at: i.updated_at,
            vendor: i.vendor,
            product_type: i.product_type,
            tags: i.tags,
            options: i.options,
            image: i.image,
            variants: i.variants.map(r => ({
                id: r.id,
                product_id: r.product_id,
                title: r.title,
                price: r.price,
                sku: r.sku,
                position: r.position,
                inventory_policy: r.inventory_policy,
                compare_at_price: r.compare_at_price,
                fulfillment_service: r.fulfillment_service,
                inventory_management: r.inventory_management,
                option1: r.option1,
                option2: r.option2,
                option3: r.option3,
                created_at: r.created_at,
                updated_at: r.updated_at,
                taxable: r.taxable,
                barcode: r.barcode,
                grams: r.grams,
                image_id: r.image_id,
                weight: r.weight,
                weight_unit: r.weight_unit,
                inventory_item_id: r.inventory_item_id,
                inventory_quantity: r.inventory_quantity,
                old_inventory_quantity: r.old_inventory_quantity,
                requires_shipping: r.requires_shipping
            })),
            images: i.images
        })),
        this.getProductByUrl = async t => {
            const s = await (await fetch(t + ".json")).json();
            return {
                id: s.product.id,
                title: s.product.title,
                handle: s.product.handle,
                body_html: s.product.body_html,
                published_at: s.product.published_at,
                created_at: s.product.created_at,
                updated_at: s.product.updated_at,
                vendor: s.product.vendor,
                product_type: s.product.product_type,
                tags: s.product.tags,
                options: s.product.options,
                image: s.product.image,
                variants: s.product.variants.map(i => ({
                    id: i.id,
                    product_id: i.product_id,
                    title: i.title,
                    price: i.price,
                    sku: i.sku,
                    position: i.position,
                    inventory_policy: i.inventory_policy,
                    compare_at_price: i.compare_at_price,
                    fulfillment_service: i.fulfillment_service,
                    inventory_management: i.inventory_management,
                    option1: i.option1,
                    option2: i.option2,
                    option3: i.option3,
                    created_at: i.created_at,
                    updated_at: i.updated_at,
                    taxable: i.taxable,
                    barcode: i.barcode,
                    grams: i.grams,
                    image_id: i.image_id,
                    weight: i.weight,
                    weight_unit: i.weight_unit,
                    inventory_item_id: i.inventory_item_id,
                    inventory_quantity: i.inventory_quantity,
                    old_inventory_quantity: i.old_inventory_quantity,
                    requires_shipping: i.requires_shipping
                })),
                images: s.product.images
            }
        }
        ,
        this.getCollection = async t => {
            const i = (await (await fetch("/collections/" + t + "/products.json")).json()).products.map(l => ({
                id: l.id,
                title: l.title,
                handle: l.handle,
                body_html: l.body_html,
                published_at: l.published_at,
                created_at: l.created_at,
                updated_at: l.updated_at,
                vendor: l.vendor,
                product_type: l.product_type,
                tags: l.tags,
                options: l.options,
                image: l.image,
                variants: l.variants.map(c => ({
                    id: c.id,
                    product_id: c.product_id,
                    title: c.title,
                    price: c.price,
                    sku: c.sku,
                    position: c.position,
                    inventory_policy: c.inventory_policy,
                    compare_at_price: c.compare_at_price,
                    fulfillment_service: c.fulfillment_service,
                    inventory_management: c.inventory_management,
                    option1: c.option1,
                    option2: c.option2,
                    option3: c.option3,
                    created_at: c.created_at,
                    updated_at: c.updated_at,
                    taxable: c.taxable,
                    barcode: c.barcode,
                    grams: c.grams,
                    image_id: c.image_id,
                    weight: c.weight,
                    weight_unit: c.weight_unit,
                    inventory_item_id: c.inventory_item_id,
                    inventory_quantity: c.inventory_quantity,
                    old_inventory_quantity: c.old_inventory_quantity,
                    requires_shipping: c.requires_shipping
                })),
                images: l.images
            }))
              , n = await (await fetch("/collections/" + t + ".json")).json();
            return {
                id: n.collection.id,
                title: n.collection.title,
                handle: n.collection.handle,
                description: n.collection.description,
                published_at: n.collection.published_at,
                updated_at: n.collection.updated_at,
                image: n.collection.image,
                products_count: n.collection.products_count,
                products: i
            }
        }
        ,
        this.getProduct = async t => {
            const s = await (await fetch("/products/" + t + ".json")).json();
            return {
                id: s.product.id,
                title: s.product.title,
                handle: s.product.handle,
                body_html: s.product.body_html,
                published_at: s.product.published_at,
                created_at: s.product.created_at,
                updated_at: s.product.updated_at,
                vendor: s.product.vendor,
                product_type: s.product.product_type,
                tags: s.product.tags,
                options: s.product.options,
                image: s.product.image,
                variants: s.product.variants.map(r => ({
                    id: r.id,
                    product_id: r.product_id,
                    title: r.title,
                    price: r.price,
                    sku: r.sku,
                    position: r.position,
                    inventory_policy: r.inventory_policy,
                    compare_at_price: r.compare_at_price,
                    fulfillment_service: r.fulfillment_service,
                    inventory_management: r.inventory_management,
                    option1: r.option1,
                    option2: r.option2,
                    option3: r.option3,
                    created_at: r.created_at,
                    updated_at: r.updated_at,
                    taxable: r.taxable,
                    barcode: r.barcode,
                    grams: r.grams,
                    image_id: r.image_id,
                    weight: r.weight,
                    weight_unit: r.weight_unit,
                    inventory_item_id: r.inventory_item_id,
                    inventory_quantity: r.inventory_quantity,
                    old_inventory_quantity: r.old_inventory_quantity,
                    requires_shipping: r.requires_shipping
                })),
                images: s.product.images
            }
        }
        ,
        this.addToCart = async (t, e, s=1, i=null, r=null) => {
            console.log("ADD TO CART", t, e, s, i, r);
            try {
                const c = (await this.getCart()).items.find(d => d.variant_id.toString() === e)
                  , u = c ? c.quantity : 0;
                if (u + s > 10 && (s = 10 - u),
                s > 0) {
                    const d = {
                        items: [{
                            id: e,
                            quantity: s,
                            selling_plan: i ? parseInt(i) : null
                        }]
                    };
                    await fetch("/cart/add.js", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(d)
                    })
                }
            } catch (l) {
                console.error("Error:", l)
            }
            r && await this.addDiscountCode(r);
            const n = await this.getCart();
            this.getProductType(),
            document.querySelector("cart-drawer") && f.updateCart(n);
            const o = document.querySelector("free-samples");
            return o == null || o.updateFreeSamplesDisplay(),
            n
        }
        ,
        this.addToCartList = async (t, e, s, i=null) => {
            try {
                const n = await this.getCart()
                  , o = {
                    items: t.map( (l, c) => {
                        let u = 1;
                        s && (u = s[c] ? s[c] : 1);
                        const d = n.items.find(m => m.variant_id.toString() === e[c])
                          , p = d ? d.quantity : 0;
                        return p + u > 10 && (u = 10 - p),
                        {
                            id: e[c],
                            quantity: u > 0 ? u : 0,
                            selling_plan: i ? parseInt(i[c]) : null
                        }
                    }
                    ).filter(l => l.quantity > 0)
                };
                await fetch("/cart/add.js", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(o)
                })
            } catch (n) {
                console.error("Error:", n)
            }
            const r = await this.getCart();
            return document.querySelector("cart-drawer") && f.updateCart(r),
            r
        }
        ,
        this.addNoteToCart = async t => {
            try {
                await fetch("/cart/update.js", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        note: t
                    })
                })
            } catch (s) {
                console.error("Error:", s)
            }
            const e = await this.getCart();
            return document.querySelector("cart-drawer") && f.updateCart(e),
            e
        }
        ,
        this.getProductType = async () => {
            const t = await this.getCart();
            let e = 0
              , s = {};
            const i = [];
            for (const n of t.items)
                n.product_type === "Free Sample" ? ((!s[n.title] || n.quantity < s[n.title].quantity) && (s[n.title] = {
                    variantId: n.variant_id.toString(),
                    quantity: n.quantity
                }),
                n.quantity !== 1 && i.push({
                    id: n.variant_id.toString(),
                    quantity: 1
                })) : (e++,
                n.quantity > 10 && i.push({
                    id: n.variant_id.toString(),
                    quantity: 10
                }));
            const r = Object.entries(s);
            e === 0 ? (i.push(...r.map( ([n,o]) => ({
                id: o.variantId,
                quantity: 0
            }))),
            s = {}) : r.length > 2 && (i.push(...r.slice(2).map( ([n,o]) => ({
                id: o.variantId,
                quantity: 0
            })), ...r.slice(0, 2).map( ([n,o]) => ({
                id: o.variantId,
                quantity: 1
            }))),
            s = Object.fromEntries(r.slice(0, 2))),
            i.length > 0 && await this.updateItemsInCart(i)
        }
        ,
        this.updateItemsInCart = async t => {
            var e;
            try {
                const i = await (await fetch("/cart/update.js", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        updates: Object.fromEntries(t.map(r => [r.id, r.quantity]))
                    })
                })).json();
                return (e = E.cartPage) == null || e.refreshCart(),
                f.updateCart(i),
                i
            } catch (s) {
                throw console.error("Error updating cart:", s),
                s
            }
        }
        ,
        this.updateCart = async (t, e, s=!1) => {
            try {
                e >= 0 && await fetch("/cart/change.js", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        id: t,
                        quantity: e
                    })
                })
            } catch (o) {
                console.error("Error:", o)
            }
            let i = await this.getCart();
            if (s)
                return i;
            const r = i.items.find(o => o.variant_id.toString() === window.giftWithPurchaseId);
            r && r.line_price > 0 && window.gwpCampaign && (i = await this.updateCart(window.giftWithPurchaseId, 0, !0)),
            document.querySelector("cart-drawer") && f.updateCart(i),
            this.getProductType();
            const n = document.querySelector("free-samples");
            return n == null || n.updateFreeSamplesDisplay(),
            i
        }
        ,
        this.clearCart = async () => {
            try {
                const e = await (await fetch("/cart/clear.js", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })).json();
                return document.querySelector("cart-drawer") && f.updateCart(e),
                this.getProductType(),
                localStorage.removeItem("gwpRemoved"),
                e
            } catch (t) {
                console.error("Error:", t)
            }
        }
        ,
        this.removeFromCart = async t => {
            const e = await this.updateCart(t, 0);
            t == window.giftWithPurchaseId && localStorage.setItem("gwpRemoved", "true"),
            e.items.length > 0 && e.items.filter(i => i.product_type === "Free Sample" || i.product_type === "Gift Wrap").length == e.items.length && await this.clearCart()
        }
        ,
        this.addDiscountCode = async t => {
            await fetch(`/discount/${t}`).then(function() {
                return fetch("/cart/update.js", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        updates: {}
                    })
                })
            })
        }
    }
    async getUpdatedDrawerItems() {
        try {
            const e = await (await fetch("/cart?view=drawer-update")).text()
              , i = new DOMParser().parseFromString(e, "text/html")
              , r = new Map;
            return i.querySelectorAll("[id]").forEach(o => {
                const l = parseInt(o.id, 10)
                  , u = o.innerHTML.replace(/[^\d.-]/g, "");
                if (!isNaN(l) && u) {
                    const d = Math.round(parseFloat(u) * 100);
                    r.set(l, d)
                }
            }
            ),
            r
        } catch (t) {
            return console.error(t),
            new Map
        }
    }
}
window.bogosY = y
class w {
    static async makeCartObject() {
        return await new y().getCart().then(s => {
            const i = s.items.map( (n, o) => {
                let l = (n.original_price / 100).toFixed(2)
                  , c = {
                    index: o,
                    item_id: n.id.toString(),
                    item_name: n.title,
                    item_brand: n.vendor,
                    price: l.toString(),
                    quantity: n.quantity.toString()
                };
                return n.variant_title && n.variant_title != "Default Title" && (c.item_variant = n.variant_title),
                c
            }
            );
            return {
                currency: s.currency,
                value: s.total_price.toString(),
                items: i
            }
        }
        )
    }
    static async makeItemListObject(t, e=null) {
        try {
            return await new y().getCollection(t).then(r => {
                const n = r.products.map( (l, c) => {
                    const u = e ? l.variants.find(p => p.id == e) : l.variants[0];
                    let d = {
                        index: c,
                        item_id: l.id.toString(),
                        item_name: l.title,
                        item_brand: l.vendor,
                        item_list_id: r.id.toString(),
                        item_list_name: r.title,
                        price: u.price.toString()
                    };
                    return u.title && u.title != "Default Title" && (d.item_variant = u.title),
                    d
                }
                );
                return {
                    currency: "USD",
                    item_list_id: r.id.toString(),
                    item_list_name: r.title,
                    items: n
                }
            }
            )
        } catch {
            return !1
        }
    }
    static async makeProductObject(t=null) {
        return await new y().getProducts().then(i => {
            const r = i[0]
              , n = t ? r.variants.find(l => l.id == t) : r.variants[0]
              , o = {
                index: 0,
                item_id: r.id.toString(),
                item_name: r.title,
                item_brand: r.vendor,
                price: n.price.toString()
            };
            return n.title && n.title != "Default Title" && (o.item_variant = n.title),
            o
        }
        )
    }
    static viewPromotion(t, e, s, i, r, n=!1) {
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const o = {
            event: "view_promotion",
            banner_type: t,
            banner_image_url: e,
            banner_text: s,
            banner_link_url: i,
            banner_position: r,
            event_source: "frontend"
        };
        window.dataLayer.push(o),
        n && console.debug(o)
    }
    static selectPromotion(t, e, s, i, r, n=!1) {
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const o = {
            event: "select_promotion",
            banner_type: t,
            banner_image_url: e,
            banner_text: s,
            banner_link_url: i,
            banner_position: r,
            event_source: "frontend"
        };
        window.dataLayer.push(o),
        n && console.debug(o)
    }
    static viewItem(t, e=!1) {
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const s = {
            event: "view_item_list",
            ecommerce: t,
            event_source: "frontend"
        };
        window.dataLayer.push(s),
        e && console.debug(s)
    }
    static viewItemEvent(t, e=!1) {
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const s = {
            event: "view_item",
            ecommerce: t,
            event_source: "frontend"
        };
        window.dataLayer.push(s),
        e && console.debug(s)
    }
    static selectItem(t, e=!1) {
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const s = {
            event: "select_item",
            ecommerce: t,
            event_source: "frontend"
        };
        window.dataLayer.push(s),
        e && console.debug(s)
    }
    static viewCart(t, e=!1) {
        if (!t)
            return;
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const s = {
            event: "view_cart",
            ecommerce: t,
            event_source: "frontend"
        };
        window.dataLayer.push(s),
        e && console.debug(s)
    }
    static addToCart(t, e=!1) {
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const s = {
            event: "add_to_cart",
            ecommerce: t,
            event_source: "frontend"
        };
        window.dataLayer.push(s),
        e && console.debug(s)
    }
    static removeFromCart(t, e=!1) {
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const s = {
            event: "remove_from_cart",
            ecommerce: t,
            event_source: "frontend"
        };
        window.dataLayer.push(s),
        e && console.debug(s)
    }
    static viewItemList(t, e=!1) {
        if (!t)
            return;
        window.dataLayer = window.dataLayer || [],
        window.dataLayer.push({
            ecommerce: null
        });
        const s = {
            event: "view_item_list",
            ecommerce: t,
            event_source: "frontend"
        };
        window.dataLayer.push(s),
        e && console.debug(s)
    }
}
class pt extends HTMLElement {
    constructor() {
        super(),
        this.slider = null,
        this.slidesTriggered = [],
        this.sliderElement = this.setupSlider(),
        this.makeSlideContentJSON(),
        this.setDataLayerEvents()
    }
    makeSlideContentJSON() {
        const t = this.querySelector("[data-banner-slider-json]");
        this.slideContent = t != null && t.textContent ? JSON.parse(t.textContent).slides : []
    }
    viewPromotionEvent(t) {
        if (this.slidesTriggered[t])
            return;
        const e = this.slideContent
          , s = window.innerWidth < 768 && e[t].image_mobile ? e[t].image_mobile : e[t].image
          , i = window.innerWidth < 768 && e[t].text_mobile ? e[t].text_mobile : e[t].text
          , r = document.createElement("img");
        r.src = s;
        const n = document.createElement("a");
        n.href = e[t].link,
        w.viewPromotion(e[t].type, r.src, i, n.href, t),
        this.slidesTriggered[t] = !0
    }
    selectPromotionEvent(t) {
        const e = this.slideContent
          , s = window.innerWidth < 768 ? e[t].image_mobile : e[t].image
          , i = window.innerWidth < 768 ? e[t].text_mobile : e[t].text
          , r = document.createElement("img");
        r.src = s;
        const n = document.createElement("a");
        n.href = e[t].link,
        w.selectPromotion(e[t].type, r.src, i, n.href, t)
    }
    setDataLayerEvents() {
        if (this.viewPromotionEvent(0),
        !this.slider)
            return;
        this.slider.onSlide(e => {
            this.viewPromotionEvent(e)
        }
        ),
        this.slideContent.forEach( (e, s) => {
            var i;
            (i = this.querySelector(`.slide-banner--${e.id}`)) == null || i.addEventListener("pointerdown", () => {
                this.selectPromotionEvent(s)
            }
            )
        }
        )
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        this.slider = new h(this.sliderElement,{
            all: {
                enableAutoplay: !0,
                autoplayInterval: 1e4,
                transitionDuration: 800,
                slidesToShow: 1,
                draggable: !0,
                stopAutoplayOnInteraction: !1
            }
        }),
        this.sliderElement
    }
}
class mt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider(),
        window.addEventListener("resize", this.checkSliderItems.bind(this)),
        this.checkSliderItems()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 4,
                draggable: !0,
                slidesToScroll: 4
            },
            "(max-width: 1120px)": {
                slidesToShow: 3,
                slidesToScroll: 3
            },
            "(max-width: 1024px)": {
                slidesToShow: 2,
                slidesToScroll: 2
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
    checkSliderItems() {
        var s, i;
        const t = this.sliderElement.querySelectorAll(".blaze-track .collection-list__collection").length;
        let e;
        window.matchMedia("(max-width: 768px)").matches ? e = 1 : window.matchMedia("(max-width: 1024px)").matches ? e = 2 : window.matchMedia("(max-width: 1120px)").matches ? e = 3 : e = 4,
        t <= e ? (s = this.sliderElement.querySelector(".blaze-container.collection-list__container")) == null || s.classList.add("hide-arrows") : (i = this.sliderElement.querySelector(".blaze-container.collection-list__container")) == null || i.classList.remove("hide-arrows")
    }
}
class ft {
    static addToCart(t, e, s, i, r, n, o, l) {
        const c = {
            Name: t,
            ProductID: e,
            Categories: s,
            ImageURL: i,
            URL: r,
            Brand: n,
            Price: o,
            CompareAtPrice: l
        };
        (window._learnq || []).push(["track", "Added to Cart", c])
    }
}
class gt extends HTMLElement {
    constructor() {
        var t, e;
        super(),
        this.productId = this.getAttribute("data-product-id"),
        this.productHandle = this.getAttribute("data-product-handle"),
        this.productType = this.getAttribute("data-product-type"),
        this.querySelector(".product-card__quantity") ? this.quantity = parseInt(this.querySelector(".product-card__quantity").value) : this.quantity = 1,
        this.querySelector(".product-card__added-text") ? this.addedText = this.querySelector(".product-card__added-text").innerText : this.addedText = "Adding to cart",
        this.fetchData = new y,
        this.productVariantSelector = this.querySelector("select.product-card__variants"),
        this.variantSelectedId = this.productVariantSelector.value,
        this.addToCartButton = this.querySelector(".product-card__add-to-cart"),
        this.addToText = this.addToCartButton ? this.addToCartButton.innerText : "Add to cart",
        this.productCompareAtPrice = this.querySelector(".product-card__price-original s"),
        this.productPrice = this.querySelector(".product-card__price"),
        this.productImage = this.querySelector(".product-card__image--main"),
        this.productName = (t = this.querySelector(".product-card__title")) == null ? void 0 : t.textContent,
        this.productUrl = ((e = this.querySelector(".product-card__link")) == null ? void 0 : e.getAttribute("href")) ?? "",
        this.productUrl = new URL(this.productUrl,window.location.origin).href,
        this.productDiscountMessage = this.querySelector(".coupon-message"),
        this.toast = new Q,
        this.addToCart(),
        this.changeVariant()
    }
    changeVariant() {
        this.productVariantSelector.addEventListener("change", t => {
            var r, n;
            const e = t.target.value
              , s = (r = this.querySelector(`option[value="${e}"]`)) == null ? void 0 : r.getAttribute("data-price")
              , i = (n = this.querySelector(`option[value="${e}"]`)) == null ? void 0 : n.getAttribute("data-compare-at-price");
            s && this.productPrice && (this.productPrice.innerText = `${s}`),
            this.productCompareAtPrice && (this.productCompareAtPrice.innerText = `${i ?? ""}`),
            this.variantSelectedId = e,
            this.updateDiscountedPrice(e),
            this.updateCompareAtPrice()
        }
        )
    }
    updateDiscountedPrice(t) {
        const e = this.querySelector(".product-card__variants")
          , s = e.options[e.selectedIndex];
        this.querySelectorAll(".coupon-message").forEach(r => {
            var u;
            if (!r)
                return;
            parseFloat(r.getAttribute("data-original-price") || "0");
            const n = parseFloat(r.getAttribute("data-discount-percentage") || "0") / 100
              , l = parseFloat(((u = s.getAttribute("data-price")) == null ? void 0 : u.replace(/[^0-9.-]+/g, "")) || "0") * (1 - n)
              , c = r.querySelector(".discounted-price");
            c && (c.textContent = this.formatCurrency(l))
        }
        )
    }
    formatCurrency(t) {
        return new Intl.NumberFormat("en-US",{
            style: "currency",
            currency: "USD"
        }).format(t)
    }
    addToCart() {
        this.addToCartButton && this.addToCartButton.addEventListener("click", async t => {
            var s;
            if (t.preventDefault(),
            !this.addToCartButton || await this.getProductType())
                return;
            this.addToCartButton.innerText = this.addedText,
            this.addToCartButton.disabled = !0,
            await this.fetchData.addToCart(this.productId, this.variantSelectedId),
            this.addToCartButton.disabled = !1,
            this.addToCartButton.innerText = this.addToText,
            await f.openCart();
            const e = {
                currency: "USD",
                value: this.productPrice.innerText,
                items: [{
                    index: 0,
                    item_id: this.productId,
                    item_name: this.productName,
                    item_brand: "",
                    price: this.productPrice.innerText,
                    quantity: this.quantity.toString()
                }]
            };
            w.addToCart(e, !0),
            ft.addToCart(this.productName, this.productId, [], this.productImage.getAttribute("src"), this.productUrl, this.productType, this.productPrice.innerText, ((s = this.productCompareAtPrice) == null ? void 0 : s.innerText) ?? "")
        }
        )
    }
    async getProductType() {
        let t = await this.fetchData.getCart();
        return t.items.length === 0 && this.productType.toLowerCase() === "free sample" ? (this.toast.showToast("You can't add free samples to an empty cart", "error"),
        !0) : this.productType.toLowerCase() === "free sample" && t.items.filter(s => s.product_type.toLowerCase() === "free sample").length >= 2 ? (this.toast.showToast("You can't add more than 2 free samples to your cart", "error"),
        !0) : !1
    }
    updateCompareAtPrice() {
        const t = this.querySelector(".product-card__price-original")
          , e = this.querySelector(".product-card__variants");
        if (!e || !t)
            return;
        const i = e.options[e.selectedIndex].getAttribute("data-compare-price");
        if (i && i.trim() !== "$0.00") {
            t.innerHTML = `<s>${i}</s>`;
            return
        }
        t.innerHTML = ""
    }
}
class yt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider(),
        this.productCards = this.setupProductCards(),
        this.makesectionContentJSON(),
        this.setDataLayerEvents()
    }
    setupProductCards() {
        const t = this.querySelectorAll("product-card")
          , e = [];
        return t.forEach(s => {
            e.push(s)
        }
        ),
        e
    }
    makesectionContentJSON() {
        const t = this.querySelector("[data-collection-section]");
        this.sectionContent = t != null && t.textContent ? JSON.parse(t.textContent) : []
    }
    async setDataLayerEvents() {
        const t = await w.makeItemListObject(this.sectionContent.collection_handle, this.sectionContent.variant_id);
        typeof t != "boolean" && (w.viewItemList(t),
        this.productCards.forEach(e => {
            e.addEventListener("click", s => {
                if (!t)
                    return;
                const i = {
                    ...t
                }
                  , r = t.items.find(n => n.item_id === e.productId);
                r && (i.items = [r],
                w.selectItem(i, !0))
            }
            )
        }
        ))
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 4,
                slidesToScroll: 4
            },
            "(max-width: 1120px)": {
                slidesToShow: 3,
                slidesToScroll: 3
            },
            "(max-width: 1024px)": {
                slidesToShow: 2,
                slidesToScroll: 2
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
}
class St extends HTMLElement {
    constructor() {
        super(),
        this.productPins = [],
        this.products = [],
        this.fetchData = new y,
        this.selectedVariant = this.querySelectorAll("select")[0].value,
        this.variantPrice = this.querySelector(`option[value="${this.selectedVariant}"]`).dataset.variantPrice || "",
        this.productPins = Array.from(this.querySelectorAll("[data-pin]")),
        this.products = Array.from(this.querySelectorAll("[data-product]")),
        this.addAllToCartButtons = this.querySelectorAll(".lookbook__add-to-cart-all"),
        this.productsMobile = document.querySelector(".lookbook_products--mobile"),
        this.productsMobileClose = document.querySelector(".lookbook_products--mobile__close"),
        this.setAddToCart(),
        this.setPinsHover(),
        this.setProductsHover(),
        this.setAddAllToCartHover(),
        this.setAddAllToCartSubmit(),
        this.setupSlider(),
        this.setPinsMobileClick(),
        this.closeProductsMobile(),
        this.setupSelect()
    }
    setAddToCart() {
        this.products.forEach(t => {
            t.querySelector(".lookbook__add-to-cart").addEventListener("click", async s => {
                s.preventDefault();
                const i = t.getAttribute("data-product-id")
                  , r = t.querySelector("[data-variant]").value;
                await this.fetchData.addToCart(i, r),
                f.openCart()
            }
            )
        }
        )
    }
    setPinsHover() {
        this.productPins.forEach(t => {
            t.addEventListener("mouseover", e => {
                const s = t.getAttribute("data-pin")
                  , i = this.products.find(r => r.getAttribute("data-product") === s);
                i == null || i.classList.add("lookbook_product--hover")
            }
            ),
            t.addEventListener("mouseout", e => {
                const s = t.getAttribute("data-pin")
                  , i = this.products.find(r => r.getAttribute("data-product") === s);
                i == null || i.classList.remove("lookbook_product--hover")
            }
            )
        }
        )
    }
    setPinsMobileClick() {
        this.productPins.forEach(t => {
            const e = t.getAttribute("data-pin")
              , s = () => {
                var i, r, n;
                window.innerWidth > 1024 || ((i = this.slider) == null || i.next(parseInt(e)),
                (r = this.productsMobile) == null || r.classList.add("lookbook_products--mobile--active"),
                (n = document.querySelector("body")) == null || n.classList.add("no-scroll"))
            }
            ;
            t.addEventListener("click", s)
        }
        )
    }
    closeProductsMobile() {
        var t;
        (t = this.productsMobileClose) == null || t.addEventListener("click", e => {
            var s, i;
            (s = this.productsMobile) == null || s.classList.remove("lookbook_products--mobile--active"),
            (i = document.querySelector("body")) == null || i.classList.remove("no-scroll"),
            setTimeout( () => {
                var r, n;
                (n = this.slider) == null || n.prev((r = this.slider) == null ? void 0 : r.stateIndex)
            }
            , 500)
        }
        )
    }
    setProductsHover() {
        this.products.forEach(t => {
            t.addEventListener("mouseover", e => {
                const s = t.getAttribute("data-product")
                  , i = this.productPins.find(r => r.getAttribute("data-pin") === s);
                i == null || i.classList.add("lookbook_image_pin--hover")
            }
            ),
            t.addEventListener("mouseout", e => {
                const s = t.getAttribute("data-product")
                  , i = this.productPins.find(r => r.getAttribute("data-pin") === s);
                i == null || i.classList.remove("lookbook_image_pin--hover")
            }
            )
        }
        )
    }
    setAddAllToCartHover() {
        this.addAllToCartButtons.forEach(t => {
            t.addEventListener("mouseover", e => {
                this.productPins.forEach(s => {
                    s.classList.add("lookbook_image_pin--hover")
                }
                ),
                this.products.forEach(s => {
                    s.classList.add("lookbook_product--hover")
                }
                )
            }
            ),
            t.addEventListener("mouseout", e => {
                this.productPins.forEach(s => {
                    s.classList.remove("lookbook_image_pin--hover")
                }
                ),
                this.products.forEach(s => {
                    s.classList.remove("lookbook_product--hover")
                }
                )
            }
            )
        }
        )
    }
    setAddAllToCartSubmit() {
        this.addAllToCartButtons.forEach(t => {
            const e = []
              , s = [];
            this.products.forEach(async i => {
                const r = i.getAttribute("data-product-id")
                  , n = i.querySelector("[data-variant]").value;
                e.push(r),
                s.push(n)
            }
            ),
            t.addEventListener("click", async i => {
                i.preventDefault(),
                await this.fetchData.addToCartList(e, s, null),
                f.openCart()
            }
            )
        }
        )
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider");
        this.slider = new h(t,{
            all: {
                slidesToShow: 1
            }
        }),
        this.lookbookArrows = this.querySelector(".blaze-slider__arrows")
    }
    setupSelect() {
        const t = this.querySelectorAll("select")
          , e = this;
        t.forEach(s => {
            s.addEventListener("change", () => {
                e.selectedVariant = s.value;
                const i = s.querySelector(`option[value="${e.selectedVariant}"]`);
                i ? e.variantPrice = i.dataset.variantPrice || "" : e.variantPrice = "";
                const r = s.closest(".lookbook_product_container");
                if (r) {
                    const n = r.querySelector(".lookbook__price");
                    n && (n.textContent = e.variantPrice),
                    e.updateCompareAtPrice(r)
                }
            }
            )
        }
        )
    }
    updateCompareAtPrice(t) {
        const e = t.querySelector(".lookbook__compared__price");
        if (!e)
            return;
        const s = t.querySelector("select");
        if (!s)
            return;
        const r = s.options[s.selectedIndex].getAttribute("data-compare-price");
        if (r && r.trim() !== "$0.00") {
            e.innerHTML = `<s>${r}</s>`;
            return
        }
        e.innerHTML = ""
    }
}
class wt extends HTMLElement {
    constructor() {
        super(),
        this.slideImages = [],
        this.autoplayInterval = 6e3,
        this.slideImages = Array.from(this.querySelectorAll("[data-news-slide-image]")),
        this.setupSlider(),
        this.setSlideImagesClick()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider");
        this.slider = new h(t,{
            all: {
                slidesToShow: 1,
                enableAutoplay: !0,
                autoplayInterval: this.autoplayInterval,
                draggable: !0
            }
        }),
        this.slideImages[0].classList.add("news-slider__slide-image--active"),
        this.slider.onSlide( () => {
            var s;
            const e = ((s = this.slider) == null ? void 0 : s.stateIndex) || 0;
            this.slideImages.forEach(i => i.classList.remove("news-slider__slide-image--active")),
            this.slideImages[e].classList.add("news-slider__slide-image--active")
        }
        )
    }
    setSlideImagesClick() {
        this.slideImages.forEach( (t, e) => {
            t.addEventListener("click", () => {
                var s, i;
                this.slider && (e - this.slider.stateIndex > 0 && ((s = this.slider) == null || s.next(e - this.slider.stateIndex)),
                e - this.slider.stateIndex < 0 && ((i = this.slider) == null || i.prev(this.slider.stateIndex - e)))
            }
            )
        }
        )
    }
}
class vt extends HTMLElement {
    constructor() {
        super(),
        this.slider = null,
        this.sliderElement = this.setupSlider(),
        this.stepIndexes = this.querySelectorAll(".steps-slider__step"),
        this.images = this.querySelectorAll(".steps-slider__image"),
        this.setupClickEvents(),
        this.updateImage(0)
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        this.slider = new h(this.sliderElement,{
            all: {
                slidesToShow: 1,
                draggable: !0
            }
        }),
        this.sliderElement.addEventListener("blaze:drag", () => {
            var e;
            const t = ((e = this.slider) == null ? void 0 : e.stateIndex) || 0;
            this.updateStepAndImage(t)
        }
        ),
        this.sliderElement
    }
    setupClickEvents() {
        var t;
        (t = this.slider) == null || t.onSlide( () => {
            var s;
            const e = ((s = this.slider) == null ? void 0 : s.stateIndex) || 0;
            this.updateStepAndImage(e)
        }
        ),
        this.stepIndexes.forEach( (e, s) => {
            e.addEventListener("click", () => {
                var n, o, l;
                const i = ((n = this.slider) == null ? void 0 : n.stateIndex) || 0;
                if (i === s)
                    return;
                const r = s - i;
                r > 0 ? (o = this.slider) == null || o.next(r) : (l = this.slider) == null || l.prev(Math.abs(r)),
                this.updateStepAndImage(s)
            }
            )
        }
        )
    }
    updateStepAndImage(t) {
        this.stepIndexes.forEach(e => {
            e.classList.remove("steps-slider__step--active")
        }
        ),
        this.stepIndexes[t].classList.add("steps-slider__step--active"),
        this.updateImage(t)
    }
    updateImage(t) {
        var s;
        (s = this.images) == null || s.forEach(i => {
            i.classList.remove("steps-slider__image--active")
        }
        );
        const e = this.images[t] || this.images[0];
        e == null || e.classList.add("steps-slider__image--active")
    }
}
class bt extends HTMLElement {
    constructor() {
        super(),
        this.form = this.querySelector("#email_subscribe2"),
        this.birthDateInput = this.querySelector("[data-birthDay]"),
        this.errorMessage = this.querySelector("[data-subscribe-error]"),
        this.birthDateInput.addEventListener("input", t => {
            this.birthDateInput.value = this.formatBirthDate(this.birthDateInput.value)
        }
        ),
        this.formEventListener()
    }
    formEventListener() {
        this.form.addEventListener("submit", t => {
            const e = this.birthDateInput.value;
            this.isValidDate(e) ? this.isFutureDate(e) ? (t.preventDefault(),
            this.showErrorMessage("Birth date cannot be in the future.")) : this.isAtLeast18YearsOld(e) ? this.errorMessage.style.display = "none" : (t.preventDefault(),
            this.showErrorMessage("You must be at least 18 years old.")) : (t.preventDefault(),
            this.showErrorMessage("Please enter a valid birth date in the format mm/yyyy."))
        }
        )
    }
    isAtLeast18YearsOld(t) {
        const e = t.split("/")
          , s = parseInt(e[0], 10)
          , i = parseInt(e[1], 10)
          , r = parseInt(e[2], 10)
          , n = new Date(r,s - 1,i)
          , o = new Date;
        let l = o.getFullYear() - n.getFullYear();
        const c = o.getMonth() - n.getMonth();
        return (c < 0 || c === 0 && o.getDate() < n.getDate()) && l--,
        l >= 18
    }
    showErrorMessage(t) {
        this.errorMessage.textContent = t,
        this.errorMessage.style.display = "block"
    }
    isFutureDate(t) {
        const e = t.split("/")
          , s = parseInt(e[0], 10)
          , i = parseInt(e[1], 10)
          , r = parseInt(e[2], 10);
        return new Date(r,s - 1,i) > new Date
    }
    isValidDate(t) {
        if (!/^(\d{2})\/(\d{2})\/(\d{4})$/.test(t))
            return !1;
        const s = t.split("/")
          , i = parseInt(s[0], 10)
          , r = parseInt(s[1], 10)
          , n = parseInt(s[2], 10)
          , o = new Date(n,i - 1,r);
        return o.getFullYear() === n && o.getMonth() + 1 === i && o.getDate() === r
    }
    formatBirthDate(t) {
        const e = t.replace(/\D/g, "")
          , s = e.length;
        return s <= 2 ? e : s <= 4 ? `${e.slice(0, 2)}/${e.slice(2)}` : `${e.slice(0, 2)}/${e.slice(2, 4)}/${e.slice(4, 8)}`
    }
}
class _t extends HTMLElement {
    constructor() {
        super(),
        this.slider = null,
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        this.getAttribute("autoplay-interval"),
        this.slider = new h(this.sliderElement,{
            all: {
                enableAutoplay: !0,
                autoplayInterval: 5e3,
                transitionDuration: 800,
                slidesToShow: 1,
                draggable: !0,
                stopAutoplayOnInteraction: !1
            }
        }),
        this.sliderElement
    }
}
class Et extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider(),
        this.updateImages(0)
    }
    updateImages(t) {
        document.querySelectorAll(".benefits-img").forEach(s => {
            s.classList.remove("normal"),
            s.classList.add("desactive")
        }
        );
        const e = document.querySelector(`.benefits-container__img[data-index="${t}"] .benefits-img`);
        e && (e.classList.remove("desactive"),
        e.classList.add("normal"))
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 1,
                loop: !0,
                transitionDuration: 400,
                draggable: !0,
                slideGap: "10px"
            },
            "(max-width: 1120px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            },
            "(max-width: 1024px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }).onSlide(e => {
            this.updateImages(e)
        }
        ),
        this.sliderElement
    }
}
class Ct extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = null,
        this.benefitBlocks = this.countBlocks(),
        this.init()
    }
    async init() {
        await this.hideExcessiveBlocks()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider")
          , e = this.benefitBlocks;
        return new h(t,{
            all: {
                slidesToShow: e,
                slideGap: "20px",
                loop: !1,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 1024px)": {
                slidesToShow: e,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: Math.min(2.2, e),
                slidesToScroll: 1
            },
            "(max-width: 500px)": {
                slidesToShow: Math.min(1.2, e),
                slidesToScroll: 1
            }
        }),
        t
    }
    countBlocks() {
        const t = document.getElementById("blockCount");
        if (!t)
            return 0;
        const e = t.getAttribute("data-count");
        if (!e)
            return 0;
        try {
            const i = JSON.parse(e).length;
            return t.setAttribute("data-count", i.toString()),
            i
        } catch {
            return 0
        }
    }
    async hideExcessiveBlocks() {
        const t = this.benefitBlocks;
        document.querySelectorAll(".benefits-caroussel__blaze-slide").forEach(s => {
            if (!s.getAttribute("data-index"))
                return;
            let i = s.getAttribute("data-index");
            t <= 3 && parseInt(i, 10) >= t && s.remove()
        }
        ),
        this.sliderElement = this.setupSlider()
    }
}
const g = (a, t=void 0) => {
    const e = document.createElement(a);
    return t && Object.entries(t).forEach( ([s,i]) => {
        e[s] = i
    }
    ),
    e
}
;
class J extends HTMLElement {
    constructor(t, e, s) {
        super(),
        this.productId = t.id.toString(),
        this.fetchData = new y,
        this.variantSelectedId = t.variants[0].id.toString(),
        this.translations = e,
        this.product = t,
        this.quantity = 1,
        this.hasHover = s,
        this.buildProductCard(this.product)
    }
    changeVariant(t) {
        t.addEventListener("change", e => {
            const s = e.target.value;
            this.variantSelectedId = s
        }
        )
    }
    addToCart(t) {
        t && t.addEventListener("click", async e => {
            e.preventDefault(),
            t && (t.innerText = this.translations.addedToCart,
            t.disabled = !0,
            await this.fetchData.addToCart(this.productId, this.variantSelectedId),
            t.disabled = !1,
            t.innerText = this.translations.addToCart)
        }
        )
    }
    buildProductCard(t) {
        const e = this.buildCardMedia(t)
          , s = this.buildCardContent(t);
        this.appendChild(e),
        this.appendChild(s)
    }
    buildCardMedia(t) {
        const e = g("a", {
            href: t.url,
            className: "product-card__link"
        })
          , s = g("div", {
            className: "product-card__image"
        })
          , i = g("img", {
            src: t.images[0],
            alt: t.title,
            loading: "lazy",
            height: "100%",
            width: "400px",
            srcset: `
                    ${t.featured_image + "&width=320"} 320w, 
                    ${t.featured_image + "&width=480"} 480w, 
                    ${t.featured_image + "&width=640"} 640w, 
                    ${t.featured_image + "&width=960"} 960w, 
                    ${t.featured_image + "&width=800"} 800w, 
                    ${t.featured_image + "&width=1200"} 1200w, 
                    ${t.featured_image + "&width=1600"} 1600w
                `,
            sizes: "(max-width: 320px) 280px, (max-width: 480px) 440px, (max-width: 640px) 600px, (max-width: 800px) 760px, (max-width: 960px) 920px, (max-width: 1200px) 1160px, 1600px"
        });
        if (i.style.height = "100%",
        i.style.width = "400px",
        i.className = "product-card__image--main",
        s.appendChild(i),
        t.images.length > 1) {
            const r = g("img", {
                src: t.images[1],
                alt: t.title,
                loading: "lazy",
                srcset: `
                        ${t.featured_image + "&width=320"} 320w, 
                        ${t.featured_image + "&width=480"} 480w, 
                        ${t.featured_image + "&width=640"} 640w, 
                        ${t.featured_image + "&width=960"} 960w, 
                        ${t.featured_image + "&width=800"} 800w, 
                        ${t.featured_image + "&width=1200"} 1200w, 
                        ${t.featured_image + "&width=1600"} 1600w
                    `,
                sizes: "(max-width: 320px) 280px, (max-width: 480px) 440px, (max-width: 640px) 600px, (max-width: 800px) 760px, (max-width: 960px) 920px, (max-width: 1200px) 1160px, 1600px"
            });
            r.style.height = "100%",
            r.style.width = "400px",
            r.className = "product-card__image--hover",
            this.hasHover && s.appendChild(r)
        }
        return e.appendChild(s),
        e
    }
    buildCardContent(t) {
        const e = g("div", {
            className: "product-card__content"
        });
        return e.appendChild(this.buildCardLink(t)),
        e.appendChild(this.buildCardTitle(t)),
        e.appendChild(this.buildCardVariantSelector(t)),
        e.appendChild(this.buildCardDescription(t)),
        e.appendChild(this.buildQuantityInput()),
        e.appendChild(this.buildAddedToCartMessage()),
        e.appendChild(this.buildCardButton(t)),
        e
    }
    buildCardLink(t) {
        const e = g("a", {
            className: "product-card__collection",
            href: t.url
        });
        return e.innerText = t.type,
        e
    }
    buildCardButton(t) {
        if (t.available) {
            const e = t.variants[0]
              , s = g("button", {
                className: "product-card__button product-card__add-to-cart",
                type: "submit",
                ariaLabel: this.translations.addToCart
            });
            return s.innerText = this.translations.addToCart,
            s.dataset.variantId = e.id.toString(),
            this.addToCart(s),
            s
        } else {
            const e = g("button", {
                className: "product-card__button product-card__sold-out",
                type: "submit",
                ariaLabel: this.translations.soldOut
            });
            return e.innerText = this.translations.soldOut,
            e
        }
    }
    buildAddedToCartMessage() {
        const t = g("span", {
            className: "product-card__added-to-cart",
            style: "display: none;"
        });
        return t.innerText = this.translations.addedToCart,
        t
    }
    buildQuantityInput() {
        return g("input", {
            className: "product-card__quantity",
            type: "hidden",
            name: "quantity",
            value: "1",
            min: "1",
            max: "10"
        })
    }
    buildCardDescription(t) {
        const e = g("div", {
            className: "product-card__description"
        })
          , s = t.description.replaceAll(/<\/?[^>]+(>|$)/g, "");
        return e.innerText = s,
        e
    }
    buildCardVariantSelector(t) {
        const e = g("div", {
            className: "product-card__variants-price__container"
        })
          , s = g("span", {
            className: "product-card__price"
        });
        s.innerText = `$ ${(t.price / 100).toFixed(2)}`;
        const i = t.variants.find(c => c.available)
          , r = t.available == !1 || (i == null ? void 0 : i.title) == "Default Title"
          , n = g("select", {
            className: "product-card__variants",
            style: r ? "display: none;" : "",
            value: i == null ? void 0 : i.id.toString()
        });
        this.changeVariant(n);
        const o = t.variants[0]
          , l = c => {
            const u = c.id == o.id
              , d = c.available
              , p = g("option", {
                value: c.id.toString()
            });
            return p.innerText = `${c.title}${d ? "" : ` - ${this.translations.soldOut}`}`,
            u && p.setAttribute("selected", "selected"),
            d || p.setAttribute("disabled", "disabled"),
            p
        }
        ;
        return t.variants.forEach(c => {
            n.appendChild(l(c))
        }
        ),
        e.appendChild(s),
        e.appendChild(n),
        e
    }
    buildCardTitle(t) {
        const e = g("a", {
            href: t.url,
            className: "product-card__link"
        })
          , s = g("h3", {
            className: "product-card__title"
        });
        return s.innerText = t.title,
        e.appendChild(s),
        e
    }
}
class xt extends HTMLElement {
    constructor() {
        super(),
        this.hasHover = !0,
        this.hasHover = this.getAttribute("data-has-hover") === "true",
        this.sliderElement = this.setupSlider(),
        this.listWrapper = this.querySelector(".blaze-track.banner-slider__track"),
        this.translations = {
            addToCart: this.getAttribute("data-translation-add-to-cart") || "Add to cart",
            addedToCart: this.getAttribute("data-translation-added-to-cart") || "Added to cart",
            soldOut: this.getAttribute("data-translation-sold-out") || "Sold out"
        },
        this.defaultProductId = this.getAttribute("data-default-product-id") || "",
        this.productId = this.getAttribute("data-product-id") || this.defaultProductId,
        this.fetchProductList(this.productId)
    }
    setupSlider() {
        this.sliderElement = this.querySelector(".blaze-slider");
        const t = window.location.pathname === "/cart"
          , e = t ? 2 : 4
          , s = t ? 2 : 4
          , i = t ? 2 : 4 - 1
          , r = t ? 2 : 4 - 2;
        return t && this.sliderElement.classList.add("pagecart-blaze-slide"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: e,
                slidesToScroll: s
            },
            "(max-width: 1120px)": {
                slidesToShow: i,
                slidesToScroll: i
            },
            "(max-width: 1024px)": {
                slidesToShow: r,
                slidesToScroll: r
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
    async fetchProductList(t) {
        try {
            const e = await fetch(`/recommendations/products.json?product_id=${t}&limit=7&intent=related`);
            if (!e.ok)
                throw new Error("Something went wrong!");
            const {products: s} = await e.json();
            s.forEach(i => {
                if (i.vendor == "DO NOT PICK" || i.tags.includes("hidden") || i.tags.includes("hidden_prod_grid"))
                    return;
                const r = new J(i,this.translations,this.hasHover);
                this.listWrapper.appendChild(r)
            }
            ),
            this.setupSlider()
        } catch (e) {
            e instanceof Error ? console.error(e.message) : console.error("An unknown error occurred")
        }
    }
}
class Lt extends HTMLElement {
    constructor() {
        super(),
        this.form = null,
        this.productsContainer = null,
        this.collectionGrid = null,
        this.desktopSelect = null,
        this.mobileSelect = null,
        this.applyButton = null,
        document.addEventListener("DOMContentLoaded", this.initialize.bind(this))
    }
    initialize() {
        var t;
        this.form = this.querySelector("#CollectionFiltersForm"),
        this.productsContainer = document.getElementById("products-list"),
        this.collectionGrid = document.querySelector("collection-grid"),
        this.desktopSelect = document.getElementById("QuantityColumns"),
        this.mobileSelect = document.getElementById("QuantityColumnsMobile"),
        this.applyButton = document.querySelector(".filter-group__apply"),
        !(!this.form || !this.productsContainer || !this.collectionGrid) && (this.addFormEventListeners(),
        this.addFilterEventListeners(),
        this.setupModalBehavior(),
        (t = this.applyButton) == null || t.addEventListener("click", () => {
            this.closeFilters()
        }
        ))
    }
    addFormEventListeners() {
        this.form && this.form.addEventListener("submit", t => {
            t.preventDefault(),
            this.updateProducts(t)
        }
        )
    }
    addFilterEventListeners() {
        if (!this.form)
            return;
        this.form.querySelectorAll(".filter-group__select, .filter-group__checkbox, .filter-group__input").forEach(e => {
            e.name === "sort_by" ? e.addEventListener("change", s => {
                s.preventDefault(),
                this.handleSortByChange(s),
                this.updateProducts(s)
            }
            ) : e.addEventListener("change", s => {
                s.preventDefault(),
                this.updateProducts(s)
            }
            )
        }
        )
    }
    handleSortByChange(t) {
        const e = t.target;
        e.checked ? this.form.querySelectorAll('input[name="sort_by"]').forEach(i => {
            i !== e && (i.checked = !1)
        }
        ) : e.checked = !0
    }
    selectGridColumns() {
        const t = this.getActiveSelect();
        if (t) {
            const e = t.value;
            this.updateGridLayout(e)
        }
    }
    updateGridLayout(t) {
        this.productsContainer && this.productsContainer.style.setProperty("--script-template-columns", `repeat(${t}, minmax(0, 1fr))`)
    }
    handleColumnChange(t) {
        const s = t.target.value;
        this.updateGridLayout(s)
    }
    getActiveSelect() {
        return window.innerWidth <= 768 ? this.mobileSelect : this.desktopSelect
    }
    handleResize() {
        this.selectGridColumns()
    }
    setupModalBehavior() {
        const t = document.getElementById("filters-container")
          , e = document.getElementById("filter-modal-overlay")
          , s = document.getElementById("open-filters")
          , i = this.querySelector(".container_exit")
          , r = () => {
            t.style.left = "-100%",
            e.style.display = "none",
            document.body.style.overflow = "auto"
        }
        ;
        s.addEventListener("click", () => {
            t.style.left = "0",
            e.style.display = "block",
            document.body.style.overflow = "hidden"
        }
        ),
        e.addEventListener("click", () => {
            r()
        }
        ),
        i.addEventListener("click", () => {
            r()
        }
        )
    }
    updateProducts(t) {
        if (t && t.preventDefault(),
        !this.form || !this.productsContainer)
            return;
        const e = new FormData(this.form)
          , s = new URLSearchParams(e)
          , i = s.toString();
        this.updateURL(i),
        this.showLoading(),
        fetch(window.location.pathname + "?" + s.toString(), {
            method: "GET",
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then(r => r.text()).then(async r => {
            await this.updateProductsContainer(r);
            const n = this.closest("collection-grid");
            n && n.selectGridColumns(),
            this.hideLoading()
        }
        ).catch(r => console.error("Error:", r))
    }
    updateURL(t) {
        const e = `${window.location.pathname}?${t}`;
        history.pushState({
            path: e
        }, "", e)
    }
    async updateProductsContainer(t) {
        var o, l, c;
        if (!this.productsContainer)
            return;
        const s = new DOMParser().parseFromString(t, "text/html")
          , i = (o = s.getElementById("products-list")) == null ? void 0 : o.innerHTML
          , r = (l = s.querySelector(".product-count p")) == null ? void 0 : l.textContent;
        i && (this.productsContainer.innerHTML = i);
        const n = (c = document.getElementById("ProductCount")) == null ? void 0 : c.querySelector("p");
        if (r && n && (n.textContent = r),
        r) {
            let u = parseInt(r.split(" ")[0]);
            const d = document.getElementById("no-results");
            u == 0 ? d.classList.add("active") : d.classList.remove("active")
        }
        this.collectionGrid && this.collectionGrid.initializeLoadMore()
    }
    closeFilters() {
        const t = document.getElementById("filters-container")
          , e = document.getElementById("filter-modal-overlay");
        t.style.left = "-100%",
        e.style.display = "none",
        document.body.style.overflow = "auto"
    }
    showLoading() {
        document.getElementById("loader").classList.add("active")
    }
    hideLoading() {
        document.getElementById("loader").classList.remove("active")
    }
}
class Tt extends HTMLElement {
    constructor() {
        var t;
        super(),
        this.desktopColumnSelectors = null,
        this.mobileColumnSelectors = null,
        this.currentPage = 1,
        this.hasMorePages = !0,
        this.loadMoreButton = null,
        this.productGrid = null,
        this.totalProducts = ((t = document.querySelector("#product-grid")) == null ? void 0 : t.getAttribute("total-products-collection")) || "0",
        this.desktopColumnSelectors = this.querySelectorAll('#QuantityColumns input[type="radio"]'),
        this.mobileColumnSelectors = this.querySelectorAll('#QuantityColumnsMobile input[type="radio"]'),
        this.desktopColumnSelectors && this.desktopColumnSelectors.forEach(e => {
            e.addEventListener("change", this.handleColumnChange.bind(this))
        }
        ),
        this.mobileColumnSelectors && this.mobileColumnSelectors.forEach(e => {
            e.addEventListener("change", this.handleColumnChange.bind(this))
        }
        ),
        this.selectGridColumns(),
        window.addEventListener("resize", this.handleResize.bind(this))
    }
    getActiveSelect() {
        return window.innerWidth <= 768 ? this.mobileColumnSelectors : this.desktopColumnSelectors
    }
    selectGridColumns() {
        const t = this.getActiveSelect()
          , e = Array.from(t).find(s => s.checked);
        if (e) {
            const s = e.value;
            this.updateGridLayout(s)
        }
    }
    handleResize() {
        this.selectGridColumns()
    }
    handleColumnChange(t) {
        const e = t.target;
        if (e.checked) {
            const s = e.value;
            this.updateGridLayout(s)
        }
    }
    updateGridLayout(t) {
        this.productGrid && this.productGrid.style.setProperty("--script-template-columns", `repeat(${t}, minmax(0, 1fr))`)
    }
    connectedCallback() {
        this.initializeLoadMore()
    }
    initializeLoadMore() {
        var t;
        this.loadMoreButton = this.querySelector("#load-more"),
        this.productGrid = this.querySelector("#products-list"),
        this.currentPage = 1,
        this.hasMorePages = !0,
        this.totalProducts = ((t = document.querySelector("#product-grid")) == null ? void 0 : t.getAttribute("total-products-collection")) || "0",
        parseInt(this.totalProducts) <= 30 && this.loadMoreButton ? this.loadMoreButton.style.display = "none" : this.loadMoreButton && (this.loadMoreButton.style.display = "inline-block"),
        this.loadMoreButton && (this.loadMoreButton.removeEventListener("click", this.loadMoreProducts.bind(this)),
        this.loadMoreButton.addEventListener("click", this.loadMoreProducts.bind(this)))
    }
    loadMoreProducts() {
        if (!this.hasMorePages || !this.loadMoreButton)
            return;
        this.loadMoreButton.classList.add("loading");
        const e = new URLSearchParams(window.location.search).toString();
        let s = `/collections/${this.getAttribute("data-collection-handle")}?page=${this.currentPage + 1}&view=ajax`;
        e && (s += `&${e}`),
        fetch(s).then(i => i.text()).then(i => {
            var l;
            const o = new DOMParser().parseFromString(i, "text/html").querySelectorAll("#products-list .product-card");
            o.length > 0 && this.productGrid ? (o.forEach(c => {
                this.productGrid.appendChild(c)
            }
            ),
            this.currentPage += 1,
            this.loadMoreButton && (this.loadMoreButton.style.display = "inline-block")) : (this.hasMorePages = !1,
            this.loadMoreButton && (this.loadMoreButton.style.display = "none")),
            (l = this.loadMoreButton) == null || l.classList.remove("loading")
        }
        ).catch( () => {
            this.loadMoreButton && this.loadMoreButton.classList.remove("loading")
        }
        )
    }
}
class qt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 2,
                draggable: !1,
                enablePagination: !0,
                slideGap: "32px"
            },
            "(max-width: 1024px)": {
                slidesToShow: 1,
                draggable: !0
            }
        }),
        this.sliderElement
    }
}
class It extends HTMLElement {
    constructor() {
        super(),
        this.listItems = Array.from(this.querySelectorAll("[data-index]")),
        this.menuList = Array.from(this.querySelectorAll("[data-index-for]")),
        this.currentItem = "1",
        this.activeMenu = this.menuList[0],
        this.activeItem = this.listItems[0],
        this.setUp()
    }
    handleShowItem(t, e) {
        this.currentItem !== t.getAttribute("data-index-for") && (this.activeMenu.classList.remove("active"),
        this.activeItem.classList.remove("active"),
        this.activeMenu = t,
        this.activeItem = e,
        this.activeMenu.classList.add("active"),
        this.activeItem.classList.add("active"),
        this.currentItem = t.getAttribute("data-index-for") || "")
    }
    setUp() {
        this.menuList.forEach( (t, e) => {
            t.addEventListener("click", () => {
                this.handleShowItem(t, this.listItems[e])
            }
            )
        }
        )
    }
}
class Pt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 3,
                loop: !1,
                transitionDuration: 400,
                draggable: !0
            },
            "(min-width: 1120px)": {
                slidesToShow: 2,
                slidesToScroll: 1,
                slideGap: "120px"
            },
            "(max-width: 1024px)": {
                slidesToShow: 2,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
}
const S = (a, t=void 0) => {
    const e = document.createElement(a);
    return t && Object.entries(t).forEach( ([s,i]) => {
        e[s] = i
    }
    ),
    e
}
;
class kt extends HTMLElement {
    constructor(t) {
        super(),
        this.debounceUpdateQuantity = (e, s) => {
            this.debounceTimer && clearTimeout(this.debounceTimer),
            this.debounceTimer = setTimeout( () => {
                this.fetchData.updateCart(e, s)
            }
            , 300)
        }
        ,
        this.product = t,
        this.fetchData = new y,
        this.debounceTimer = void 0
    }
    formatPrice(t) {
        return t === 0 ? window.translations.freeProduct : `$${(t / 100).toFixed(2)}`
    }
    decodeHtml(t) {
        const e = document.createElement("textarea");
        return e.innerHTML = t,
        e.value
    }
    render(t) {
        var L, T;
        let e = t.compare_at_price;
        t.quantity > 1 && (e = e * t.quantity);
        const s = S("img", {
            src: t.featured_image.url,
            alt: t.featured_image.alt,
            className: "cart-product-item__image"
        })
          , i = S("div", {
            className: "cart-product-item__content"
        })
          , r = S("a", {
            innerText: this.decodeHtml(t.title),
            className: t.product_type === "Free Sample" || t.product_type === "Free Gift" || t.product_type === "Gift Wrap" ? "non_propagation-cart-product-item" : "cart-product-item__title",
            href: t.url
        })
          , n = S("p", {
            innerText: this.formatPrice(t.line_price),
            className: "cart-product-item__price"
        })
          , o = document.querySelector(".price-compare-container-shop-bar")
          , l = (T = (L = o == null ? void 0 : o.querySelector("s")) == null ? void 0 : L.innerHTML) == null ? void 0 : T.trim();
        let c = null;
        o && o.innerHTML && t.product_type !== "Free Gift" && l !== this.formatPrice(t.line_price) && (c = o.innerHTML),
        !c && e > 0 && (c = `<s>${this.formatPrice(e)}</s>`);
        const u = c ? S("p", {
            innerHTML: c,
            className: "cart-product-item__compared-price"
        }) : null
          , d = S("div", {
            className: "cart-product-item__quantity-container"
        })
          , p = S("div")
          , m = S("input", {
            type: "text",
            readOnly: "true",
            value: t.quantity.toString(),
            className: `cart-product-item__quantity-input ${t.price === 0 ? "cart-product-item__quantity-input__free" : ""}`
        })
          , v = S("button", {
            innerText: "+",
            ariaLabel: `Increase quantity of ${t.title}`,
            className: "cart-product-item__quantity-button"
        });
        v.addEventListener("click", this.incrementQuantity(m, t.variant_id.toString()));
        const b = S("button", {
            innerText: "-",
            ariaLabel: `Decrease quantity of ${t.title}`,
            className: "cart-product-item__quantity-button"
        });
        b.addEventListener("click", this.decrementQuantity(m, t.variant_id.toString()));
        const x = S("button", {
            innerText: this.removeTranslation || "Remove",
            ariaLabel: `Remove ${t.title} from cart`,
            className: "cart-product-item__remove-button"
        });
        if (x.addEventListener("click", () => {
            this.fetchData.removeFromCart(t.variant_id.toString())
        }
        ),
        t.line_price > 0 && p.appendChild(b),
        p.appendChild(m),
        t.line_price > 0 && p.appendChild(v),
        d.appendChild(p),
        d.appendChild(x),
        i.appendChild(r),
        i.appendChild(n),
        u && i.appendChild(u),
        !/default/i.test(t.variant_title) && !/unique/i.test(t.variant_title)) {
            const P = S("p", {
                innerText: t.variant_title,
                className: "cart-product-item__variant"
            });
            i.appendChild(P)
        }
        i.appendChild(d),
        this.appendChild(s),
        this.appendChild(i)
    }
    incrementQuantity(t, e) {
        return s => {
            t.value = (parseInt(t.value) + 1).toString(),
            this.debounceUpdateQuantity(e, parseInt(t.value))
        }
    }
    decrementQuantity(t, e) {
        return s => {
            parseInt(t.value) > 1 && (t.value = (parseInt(t.value) - 1).toString(),
            this.debounceUpdateQuantity(e, parseInt(t.value)))
        }
    }
}
class Mt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 5,
                loop: !0,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 1120px)": {
                slidesToShow: 4,
                slidesToScroll: 1
            },
            "(max-width: 1024px)": {
                slidesToShow: 3,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 2,
                slidesToScroll: 1
            },
            "(max-width: 425px)": {
                slidesToShow: 1.2,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
}
class At extends HTMLElement {
    constructor() {
        super(),
        this.fields = document.querySelectorAll(".account_form__container .field"),
        this.passwordInput = document.querySelector(".field_password .input_account"),
        this.setupInputsValidation(this.fields),
        this.passwordInput && this.setupPasswordReveal(this.passwordInput)
    }
    setupPasswordReveal(t) {
        var s;
        const e = (s = t.parentElement) == null ? void 0 : s.querySelector(".icon_password_reveal");
        e == null || e.addEventListener("click", () => {
            const i = t.getAttribute("type") === "password";
            t.setAttribute("type", i ? "text" : "password"),
            e.toggleAttribute("active")
        }
        )
    }
    setupInputsValidation(t) {
        t.forEach(e => {
            const s = e.querySelector(".input_account");
            s == null || s.addEventListener("click", () => {
                this.handleInputClick(t, e)
            }
            )
        }
        )
    }
    handleInputClick(t, e) {
        var i, r, n;
        e.classList.contains("input_error") && (e.classList.remove("input_error"),
        (i = e.querySelector(".form__message")) == null || i.remove()),
        Array.from(t).every(o => !o.classList.contains("input_error")) && ((r = document.querySelector(".form_errors")) == null || r.setAttribute("hidden", ""),
        (n = document.querySelector(".errors")) == null || n.setAttribute("hidden", ""))
    }
}
class Dt extends HTMLElement {
    constructor() {
        super(),
        this.quantity = 1,
        this.subscribeInputs = this.querySelectorAll('input[type="radio"][name="subscribe"]'),
        this.subscribeId = null,
        this.selectedVariant = this.querySelectorAll("select")[0].value,
        this.variantPrice = this.querySelector(`option[value="${this.selectedVariant}"]`).dataset.variantPrice || "",
        this.tagsElement = this.querySelector("[data-product-tags]"),
        this.fetchData = new y,
        this.mainImg = this.querySelector(".main-product__images-main"),
        this.select = this.querySelector(".main-product__form-variant"),
        this.blazeSlider = this.querySelectorAll(".blaze-slide.product-image-slide"),
        this.fixedBarSelect = this.querySelector(".shop-bar-variant-selector"),
        this.fixedBarImg = this.querySelector(".main-product__fixed-bar-product-image"),
        this.setup()
    }
    setup() {
        requestAnimationFrame( () => {
            this.checkAndRedirectIfHidden()
        }
        ),
        this.setupSelect(),
        this.setupBuyButton(),
        this.setupQuantity(),
        this.setupSubscribe(),
        this.setupSliderInteractions()
    }
    checkAndRedirectIfHidden() {
        if (this.tagsElement) {
            const t = this.tagsElement.dataset.productTags;
            t && t.split(",").map(s => s.trim()).includes("hidden") && (window.location.href = "/")
        }
    }
    setupSubscribe() {
        this.setupSubscribePriceOnShopBar(),
        this.subscribeInputs.forEach(e => {
            const s = this.querySelector("[data-subscribe-box]");
            e.addEventListener("change", i => {
                i.target.hasAttribute("data-subscribe-input") ? s.classList.add("main-product__form-radio-subscribe-box--active") : (s.classList.remove("main-product__form-radio-subscribe-box--active"),
                this.subscribeId = null)
            }
            )
        }
        ),
        this.querySelectorAll('input[name="subscription"]').forEach(e => {
            e.addEventListener("change", s => {
                const i = s.target;
                this.subscribeId = i.value
            }
            )
        }
        )
    }
    setupSubscribePriceOnShopBar() {
        const t = document.querySelector(".price-before-percentage")
          , e = document.querySelector(".price-container-shop-bar")
          , s = document.querySelector("#one-time-purchase")
          , i = document.querySelector("#subscribe")
          , r = document.querySelector(".price-compare-container-shop-bar")
          , n = document.querySelector(".price-normal")
          , o = document.querySelectorAll(".subscribe-option-input");
        if (!i || !e || !t || !s || !n || !o) {
            console.warn("Missing elements for subscription price setup.");
            return
        }
        this.setupSubscriptionListener(i, e, t, r, n),
        this.setupRegularPriceListener(s, e, n, o, r)
    }
    setupSubscriptionListener(t, e, s, i, r) {
        t.addEventListener("click", () => {
            t.checked && i && (e.innerHTML = s.innerHTML,
            i.innerHTML = `<s>${r.innerHTML}</s>`)
        }
        )
    }
    setupRegularPriceListener(t, e, s, i, r) {
        t.addEventListener("click", () => {
            r.innerHTML = "",
            i.forEach(n => {
                n.checked = !1
            }
            ),
            t.checked && (e.innerHTML = s.innerHTML)
        }
        )
    }
    setupQuantity() {
        const t = this.querySelector("[data-main-product-quantity]")
          , e = this.querySelector("[data-main-product-quantity-decrease]")
          , s = this.querySelector("[data-main-product-quantity-increase]");
        e.addEventListener("click", () => {
            Number(t.value) > 1 && (this.quantity -= 1,
            t.value = `${this.quantity}`,
            this.quantity === 1 && (e.disabled = !0))
        }
        ),
        s.addEventListener("click", () => {
            this.quantity += 1,
            t.value = `${this.quantity}`,
            e.disabled = !1
        }
        ),
        t.addEventListener("change", i => {
            Number(t.value) < 1 || isNaN(Number(t.value)) ? t.value = this.quantity.toString() : this.quantity = Number(t.value),
            Number(t.value) === 1 ? e.disabled = !0 : e.disabled = !1
        }
        )
    }
    setupBuyButton() {
        this.querySelectorAll("[data-main-product-buy-button]").forEach(e => {
            e.addEventListener("click", async () => {
                e.innerHTML = e.dataset.loadingText || e.innerHTML,
                e.disabled = !0,
                await this.fetchData.addToCart("", this.selectedVariant, this.quantity, this.subscribeId),
                e.disabled = !1,
                e.innerHTML = e.dataset.successText || e.innerHTML,
                await f.openCart()
            }
            )
        }
        )
    }
    setupSelect() {
        const t = this.querySelectorAll("select")
          , e = this.querySelectorAll("[data-main-product-price]")
          , s = this
          , i = this.querySelector("[data-percentage]")
          , r = this.querySelector(".price-before-percentage");
        t.forEach(n => {
            n.addEventListener("change", () => {
                if (s.selectedVariant = n.value,
                s.variantPrice = n.querySelector(`option[value="${s.selectedVariant}"]`).dataset.variantPrice || "",
                t.forEach(o => {
                    o.value = s.selectedVariant
                }
                ),
                i) {
                    const o = i.dataset.percentage || "0"
                      , l = s.variantPrice.replace(/[^\d.]/g, "")
                      , c = (parseFloat(l) - parseFloat(l) * parseFloat(o) / 100).toFixed(2);
                    r.textContent = c
                }
                e.forEach(o => {
                    o.textContent = s.variantPrice
                }
                ),
                s.updateDiscountedPrice(s.selectedVariant),
                s.updateCompareAtPrice()
            }
            )
        }
        )
    }
    updateDiscountedPrice(t) {
        const e = this.querySelector(".main-product__form-variant")
          , s = e.options[e.selectedIndex];
        this.querySelectorAll(".coupon-message").forEach(r => {
            var u;
            if (!r)
                return;
            parseFloat(r.getAttribute("data-original-price") || "0");
            const n = parseFloat(r.getAttribute("data-discount-percentage") || "0") / 100
              , l = parseFloat(((u = s.getAttribute("data-variant-price")) == null ? void 0 : u.replace(/[^0-9.-]+/g, "")) || "0") * (1 - n)
              , c = r.querySelector(".discounted-price");
            c && (c.textContent = this.formatCurrency(l))
        }
        )
    }
    updateCompareAtPrice() {
        const t = this.querySelector(".main-product__form-price-desktop-original")
          , e = this.querySelector(".main-product__form-price-mobile-original")
          , s = this.querySelector(".main-product__form-variant")
          , i = this.querySelector(".price-compare-container-shop-bar");
        if (!s || !t || !e || !i)
            return;
        const n = s.options[s.selectedIndex].getAttribute("data-compare-price");
        if (n && n.trim() !== "$0.00") {
            t.innerHTML = `<s>${n}</s>`,
            e.innerHTML = `<s>${n}</s>`,
            i.innerHTML = `<s>${n}</s>`;
            return
        }
        e.innerHTML = "",
        t.innerHTML = "",
        i.innerHTML = ""
    }
    formatCurrency(t) {
        return new Intl.NumberFormat("en-US",{
            style: "currency",
            currency: "USD"
        }).format(t)
    }
    setupSliderInteractions() {
        this.select && this.select.addEventListener("change", () => this.updateSlidesBasedOnSelect()),
        this.blazeSlider.forEach(t => {
            t.addEventListener("click", () => this.handleSlideClick(t))
        }
        ),
        this.fixedBarSelect && this.fixedBarSelect.addEventListener("change", () => this.handleFixedBarSelectChange())
    }
    updateFixedBarImage(t) {
        this.fixedBarSelect && Array.from(this.fixedBarSelect.options).length > 1 && this.fixedBarImg && t && (this.fixedBarImg.src = t)
    }
    updateSlidesBasedOnSelect() {
        if (!this.select || !this.mainImg)
            return;
        const e = this.select.options[this.select.selectedIndex].getAttribute("data-variant-img");
        if (!e)
            return;
        this.mainImg.src = e,
        this.updateFixedBarImage(e),
        this.deactivateAllSlides();
        const s = Array.from(this.blazeSlider).find(i => {
            const r = i.querySelector("img");
            return r && r.getAttribute("src") === e
        }
        );
        s && (s.classList.add("active"),
        window.innerWidth < 768 && s.parentElement.prepend(s)),
        this.updateFixedBarBasedOnSelect()
    }
    updateSelectBasedOnActiveSlide() {
        var e;
        const t = this.querySelector(".blaze-slide.product-image-slide.active");
        if (t && this.select) {
            const s = ((e = t.querySelector("img")) == null ? void 0 : e.getAttribute("src")) || "";
            this.updateFixedBarImage(s);
            const i = this.findSelectedOption(s);
            if (i) {
                this.select.value = i.value;
                const r = new Event("change",{
                    bubbles: !0
                });
                this.select.dispatchEvent(r)
            }
        }
    }
    updateFixedBarBasedOnSelect() {
        var e, s;
        if (!this.fixedBarSelect)
            return;
        const t = Array.from(this.blazeSlider).find(i => i.classList.contains("active"));
        if (t) {
            const i = ((e = t.querySelector("img")) == null ? void 0 : e.getAttribute("src")) || "";
            this.updateFixedBarImage(i);
            const r = this.findSelectedOption(((s = this.select) == null ? void 0 : s.value) || "");
            r && (this.fixedBarSelect.value = r.value)
        }
    }
    handleSlideClick(t) {
        this.deactivateAllSlides(),
        t.classList.add("active"),
        this.updateSelectBasedOnActiveSlide()
    }
    handleFixedBarSelectChange() {
        var s, i;
        const t = (s = this.fixedBarSelect) == null ? void 0 : s.options[this.fixedBarSelect.selectedIndex].getAttribute("data-variant-img")
          , e = this.findSelectedOption(t || "");
        e && this.select && (this.select.value = e.value,
        (i = this.select) == null || i.dispatchEvent(new Event("change",{
            bubbles: !0
        })))
    }
    deactivateAllSlides() {
        this.blazeSlider.forEach(t => t.classList.remove("active"))
    }
    findSelectedOption(t) {
        var e;
        return Array.from(((e = this.select) == null ? void 0 : e.options) || []).find(s => s.getAttribute("data-variant-img") === t)
    }
}
class Bt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 2,
                loop: !0,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 550px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
}
class Ot extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider(),
        this.updateImages(0)
    }
    updateImages(t) {
        document.querySelectorAll(".benefits-img").forEach(s => {
            s.classList.remove("normal"),
            s.classList.add("desactive")
        }
        );
        const e = document.querySelector(`.benefits-container__img[data-index="${t}"] .benefits-img`);
        e && (e.classList.remove("desactive"),
        e.classList.add("normal"))
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 1,
                loop: !0,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 1120px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            },
            "(max-width: 1024px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }).onSlide(e => {
            this.updateImages(e)
        }
        ),
        this.sliderElement
    }
}
class Ht extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 3,
                loop: !0,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 1023px)": {
                slidesToShow: 2,
                slidesToScroll: 1
            },
            "(max-width: 500px)": {
                slidesToShow: 1.3,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
}
class Ft extends HTMLElement {
    constructor() {
        var t;
        super(),
        this.quantity = 1,
        this.slides = (t = this.querySelector(".blaze-track")) == null ? void 0 : t.children,
        this.mainSlide = this.querySelector(".main-offer__images-main"),
        this.selectedVariant = this.querySelectorAll("select")[0].value,
        this.variantPrice = this.querySelector(`option[value="${this.selectedVariant}"]`).dataset.variantPrice || "",
        this.fetchData = new y,
        this.setup()
    }
    setup() {
        this.setupVariantSelect(),
        this.setupVariantInputs(),
        this.setupBuyButton(),
        this.setupQuantity()
    }
    setupQuantity() {
        const t = this.querySelector("[data-main-offer-quantity]")
          , e = this.querySelector("[data-main-offer-quantity-decrease]")
          , s = this.querySelector("[data-main-offer-quantity-increase]");
        e.addEventListener("click", () => {
            Number(t.value) > 1 && (this.quantity -= 1,
            t.value = `${this.quantity}`,
            this.quantity === 1 && (e.disabled = !0))
        }
        ),
        s.addEventListener("click", () => {
            this.quantity += 1,
            t.value = `${this.quantity}`,
            e.disabled = !1
        }
        ),
        t.addEventListener("change", i => {
            Number(t.value) < 1 || isNaN(Number(t.value)) ? t.value = this.quantity.toString() : this.quantity = Number(t.value),
            Number(t.value) === 1 ? e.disabled = !0 : e.disabled = !1
        }
        )
    }
    setupBuyButton() {
        this.querySelectorAll("[data-main-offer-buy-button]").forEach(e => {
            e.addEventListener("click", async () => {
                await this.fetchData.addToCart("", this.selectedVariant, this.quantity)
            }
            )
        }
        )
    }
    setupVariantSelect() {
        const t = this.querySelector("select")
          , e = this;
        t.addEventListener("change", () => {
            e.selectedVariant = t.value,
            e.variantPrice = t.querySelector(`option[value="${e.selectedVariant}"]`).dataset.variantPrice || "",
            e.selectedVariantChanged()
        }
        )
    }
    setupVariantInputs() {
        const t = this.querySelectorAll('input[type="radio"][name="offer-variant"]')
          , e = this;
        t.forEach(s => {
            s.addEventListener("change", () => {
                s.checked && (e.selectedVariant = s.value,
                e.variantPrice = s.dataset.variantPrice || "",
                e.selectedVariantChanged())
            }
            )
        }
        )
    }
    selectedVariantChanged() {
        const t = this.querySelectorAll("select")
          , e = this.querySelectorAll('input[type="radio"][name="offer-variant"]')
          , s = this.querySelectorAll("[data-main-offer-price]");
        t.forEach(i => {
            i.value = this.selectedVariant
        }
        ),
        e.forEach(i => {
            i.checked = this.selectedVariant === i.value
        }
        ),
        s.forEach(i => {
            i.textContent = this.variantPrice
        }
        )
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider")
          , e = new h(t,{
            all: {
                slidesToShow: 4,
                draggable: !0
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        });
        Array.from(this.slides).forEach( (s, i) => {
            s.dataset.index = `${i}`,
            s.addEventListener("click", () => {
                const r = i - e.stateIndex;
                r > 0 ? e.next(r) : r < 0 && e.prev(Math.abs(r))
            }
            )
        }
        ),
        this.setupOnSlide(e)
    }
    setupOnSlide(t) {
        t.onSlide( () => {
            var e, s;
            this.mainSlide.src = ((e = this.slides[0].querySelector("img")) == null ? void 0 : e.src) || "",
            this.mainSlide.alt = ((s = this.slides[0].querySelector("img")) == null ? void 0 : s.alt) || ""
        }
        )
    }
}
class $t extends HTMLElement {
    constructor() {
        var t;
        super(),
        this.slides = (t = this.querySelector(".blaze-track")) == null ? void 0 : t.children,
        this.mainSlide = this.querySelector(`.${this.getAttribute("data-base-class")}__images-main`),
        this.slider = void 0,
        this.setup()
    }
    setup() {
        this.setupSlider(),
        this.setupZoom()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider");
        this.slider = new h(t,{
            all: {
                slidesToShow: 4,
                draggable: !0,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }),
        this.slider.onSlide( () => {
            var o;
            const e = document.querySelector(".main-product__form-variant")
              , s = document.querySelectorAll(".blaze-slide.product-image-slide")
              , i = Array.from(s);
            i.forEach(l => l.classList.remove("active")),
            i.length > 0 && i[0].classList.add("active");
            const r = document.querySelector(".blaze-slide.product-image-slide.active")
              , n = (o = r == null ? void 0 : r.querySelector("img")) == null ? void 0 : o.getAttribute("src");
            if (n) {
                const l = Array.from(e.options).find(c => c.getAttribute("data-variant-img") === n);
                l && e.value !== l.value && (e.value = l.value,
                e.dispatchEvent(new Event("change",{
                    bubbles: !0
                })))
            }
        }
        ),
        this.setupOnSlide(this.slider),
        this.slides.length <= 4 && this.querySelectorAll(".main-product__arrow-btn").forEach(e => {
            e.classList.add("hidden")
        }
        )
    }
    setupOnSlide(t) {
        Array.from(this.slides).forEach(s => s.classList.remove("active")),
        this.slides[0].classList.add("active");
        const e = Array.from(this.slides);
        e.forEach( (s, i) => {
            s.addEventListener("click", () => {
                var n, o;
                const r = i - t.stateIndex;
                r > 0 && t.next(r),
                r < 0 && t.prev(r * -1),
                e.forEach(l => l.classList.remove("active")),
                s.classList.add("active"),
                this.mainSlide.src = ((n = s.querySelector("img")) == null ? void 0 : n.src) || "",
                this.mainSlide.alt = ((o = s.querySelector("img")) == null ? void 0 : o.alt) || "",
                this.setupZoom()
            }
            )
        }
        )
    }
    setupZoom() {
        const t = document.querySelector(".zoom-container")
          , e = document.querySelector(".zoom-lens")
          , s = document.querySelector(".zoom-result")
          , i = t == null ? void 0 : t.querySelector(".product__media.zoom-container img");
        if (!t || !e || !s || !i) {
            console.warn("One or more zoom elements not found.  Zoom functionality will not work.");
            return
        }
        if (i.complete) {
            this.initializeZoom(i, t, e, s);
            return
        }
        i.onload = () => {
            this.initializeZoom(i, t, e, s)
        }
    }
    initializeZoom(t, e, s, i) {
        const r = t.getBoundingClientRect()
          , n = s.getBoundingClientRect()
          , o = i.getBoundingClientRect();
        i.style.backgroundImage = `url(${t.src})`,
        i.style.backgroundSize = "",
        i.style.backgroundPosition = "",
        t.removeEventListener("mousemove", c),
        t.removeEventListener("mouseleave", l),
        t.addEventListener("mousemove", c),
        t.addEventListener("mouseleave", l);
        function l() {
            s.style.visibility = "hidden",
            i.style.visibility = "hidden"
        }
        function c(d) {
            s.style.visibility = "visible",
            s.style.opacity = "1",
            i.style.opacity = "1",
            i.style.visibility = "visible";
            const {x: p, y: m} = u(d);
            s.style.left = p + "px",
            s.style.top = m + "px";
            let v = o.width / n.width
              , b = o.height / n.height;
            i.style.backgroundSize = `${r.width * v}px ${r.height * b}px`,
            i.style.backgroundPosition = `-${p * v}px -${m * b}px`
        }
        function u(d) {
            const p = t.getBoundingClientRect()
              , m = s.getBoundingClientRect();
            let v = d.clientX - p.left - m.width / 2
              , b = d.clientY - p.top - m.height / 2;
            const x = 0
              , L = 0
              , T = p.width - m.width
              , P = p.height - m.height;
            return v = Math.max(x, Math.min(v, T)),
            b = Math.max(L, Math.min(b, P)),
            {
                x: v,
                y: b
            }
        }
    }
}
class Nt extends HTMLElement {
    constructor() {
        super(),
        this.tabs = document.querySelectorAll('[data-toggle="tab"]'),
        this.initTabs(),
        this.firstTab(),
        this.checkUrl()
    }
    initTabs() {
        this.tabs.forEach(t => {
            t.addEventListener("click", e => {
                e.preventDefault(),
                this.handleTabClick(t)
            }
            )
        }
        )
    }
    firstTab() {
        this.tabs[0].classList.add("active")
    }
    handleTabClick(t) {
        this.tabs.forEach(s => {
            s.classList.remove("active")
        }
        ),
        t.classList.add("active");
        const e = t.getAttribute("data-url");
        window.location.href = e
    }
    checkUrl() {
        window.location.pathname.includes("/account/orders/") && this.tabs.forEach(t => {
            t.classList.remove("active")
        }
        )
    }
}
class zt extends HTMLElement {
    constructor() {
        super(),
        this.menu = this.querySelector(".product-details__menu"),
        this.tabs = this.querySelector(".product-details__content-list"),
        this.setup()
    }
    setup() {
        this.setupEventListeners()
    }
    setupEventListeners() {
        this.menu.querySelectorAll("button").forEach(t => {
            const e = this.tabs.querySelector(`[data-content-index="${t.getAttribute("data-menu-index")}"]`);
            t.addEventListener("click", () => this.handleMenuClick(t, e))
        }
        )
    }
    handleMenuClick(t, e) {
        var s;
        this.menu.querySelectorAll("button").forEach(i => {
            var r;
            (r = i.parentElement) == null || r.classList.remove("active")
        }
        ),
        this.tabs.querySelectorAll(".product-details__content-item").forEach(i => {
            i.classList.remove("active")
        }
        ),
        (s = t.parentElement) == null || s.classList.add("active"),
        e.classList.add("active")
    }
}
class Wt extends HTMLElement {
    constructor() {
        super(),
        this.slider = null,
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.slider = new h(this,{
            all: {
                slidesToShow: 1,
                draggable: !1
            }
        }),
        this.sliderElement
    }
}
class jt extends HTMLElement {
    constructor() {
        super(),
        this.review = {
            count: null,
            average: null
        },
        this.svgTemplate = `
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
        <path d="M9.08155 0.632742C9.42863 -0.173221 10.5714 -0.173221 10.9185 0.632742L12.7393 4.86101C12.8841 5.19719 13.201 5.42741 13.5654 5.46121L18.1494 5.88636C19.0232 5.9674 19.3763 7.05422 18.7171 7.63337L15.2584 10.6717C14.9835 10.9133 14.8624 11.2858 14.9429 11.6429L15.9551 16.1339C16.148 16.99 15.2235 17.6616 14.469 17.2136L10.5106 14.8632C10.1958 14.6763 9.80416 14.6763 9.48944 14.8632L5.531 17.2136C4.77647 17.6616 3.85197 16.99 4.04491 16.1339L5.0571 11.6429C5.13758 11.2858 5.01654 10.9133 4.74156 10.6717L1.28292 7.63337C0.623657 7.05422 0.976783 5.9674 1.85055 5.88636L6.43456 5.46121C6.79902 5.42741 7.11589 5.19719 7.26066 4.86101L9.08155 0.632742Z" fill="currentColor"/>
      </svg>
    `,
        this.fetchReviews(this.getAttribute("data-product-id"))
    }
    async fetchReviews(t) {
        if (!t)
            return;
        const s = `https://api-cdn.yotpo.com/v1/widget/FTTS7QMqLHrJ19cytzPDSUk0uJN7B0E40VuC19LU/products/${t}/reviews.json`;
        try {
            const r = await (await fetch(s)).json();
            r.response.bottomline && (this.review = {
                count: r.response.bottomline.total_review,
                average: r.response.bottomline.average_score
            }),
            this.render()
        } catch (i) {
            console.error("Error fetching reviews:", i)
        }
    }
    render() {
        var i, r;
        const t = this.createCountElement()
          , e = this.createAverageElement()
          , s = this.createWriteReviewLink();
        e.textContent = ` ${(i = this.review.average) == null ? void 0 : i.toFixed(1)}` || "",
        t.textContent = ` | (${(r = this.review.count) == null ? void 0 : r.toString()} reviews)`,
        this.renderStars(),
        this.appendChild(s),
        s.appendChild(e),
        s.appendChild(t),
        this.style.display = ""
    }
    createWriteReviewLink() {
        const t = document.createElement("a");
        t.href = "#yotpo-reviews-main-widget";
        const e = this.renderStars();
        return t.appendChild(e),
        t.style.cursor = "pointer",
        t.addEventListener("click", this.scrollToReviews.bind(this)),
        t
    }
    createCountElement() {
        const t = document.createElement("span");
        return t.textContent = " | (0 reviews)",
        t
    }
    createAverageElement() {
        const t = document.createElement("b");
        return t.textContent = "0.0",
        t
    }
    renderStars() {
        const t = document.createElement("div");
        t.style.display = "inline-block";
        const e = this.review.average || 0
          , s = Math.floor(e)
          , i = (e - s) * 100;
        for (let r = 0; r < 5; r++) {
            const n = document.createElement("span");
            n.style.position = "relative",
            n.style.display = "inline-block",
            n.style.width = "20px",
            n.style.height = "18px";
            const o = document.createElement("div");
            if (o.innerHTML = this.svgTemplate,
            o.style.color = r < s ? "#f39c12" : "#ccc",
            r === s && i > 0) {
                const l = document.createElement("div");
                l.innerHTML = this.svgTemplate,
                l.style.position = "absolute",
                l.style.top = "0",
                l.style.left = "0",
                l.style.width = `${i}%`,
                l.style.overflow = "hidden",
                l.style.color = "#f39c12",
                n.appendChild(l)
            }
            n.appendChild(o),
            t.appendChild(n)
        }
        return t
    }
    scrollToReviews(t) {
        t.preventDefault();
        const e = document.querySelector(".yotpo-widget-instance");
        e && e.scrollIntoView({
            behavior: "smooth"
        })
    }
}
class Gt extends HTMLElement {
    constructor() {
        super(),
        this.totalHeight = 0,
        this.progressBar = null,
        this.scrollClass = this.getAttribute("data-class"),
        this.setUpScrollEvent(),
        this.renderScroll()
    }
    renderScroll() {
        const t = document.createElement("span");
        t.classList.add("styled-scroll__main");
        const e = document.createElement("span");
        e.classList.add("styled-scroll__thumb"),
        t.appendChild(e),
        this.appendChild(t),
        this.progressBar = e
    }
    setUpScrollEvent() {
        const e = this.children[0];
        e.addEventListener("scroll", () => {
            const {scrollTop: s, scrollHeight: i, clientHeight: r} = e;
            this.progressBar.style.height = `${s / (i - r) * 100}%`
        }
        )
    }
}
class Vt extends HTMLElement {
    constructor() {
        super(),
        this.blazeSlider = null,
        this.slider = null,
        this.makeBannerContentJSON(),
        this.setDataLayerEvents(),
        this.slides = this.querySelectorAll(".blaze-slide-thin-banner"),
        this.slides.length > 1 && this.setupSliderElement()
    }
    setupSliderElement() {
        if (this.sliderElement = this.querySelector(".blaze-slider-thin-banner"),
        !!this.sliderElement)
            return this.blazeSlider = new h(this.sliderElement,{
                all: {
                    enableAutoplay: !0,
                    autoplayInterval: 1e4,
                    transitionDuration: 800,
                    slidesToShow: 1,
                    draggable: !0,
                    stopAutoplayOnInteraction: !1
                }
            }),
            this.sliderElement
    }
    makeBannerContentJSON() {
        const t = this.querySelector("[data-banner-slider-json]");
        this.bannerContent = t != null && t.textContent ? JSON.parse(t.textContent) : []
    }
    viewPromotionEvent(t=0) {
        const e = this.bannerContent
          , s = window.innerWidth < 768 ? e.image_mobile : e.image
          , i = window.innerWidth < 768 ? e.text_mobile : e.text
          , r = document.createElement("img");
        r.src = s;
        const n = document.createElement("a");
        n.href = e.link;
        const o = document.createElement("a");
        o.href = e.link_mobile;
        const l = window.innerWidth < 768 ? o : n;
        w.viewPromotion(e.type, r.src, i, l.href, t)
    }
    selectPromotionEvent(t=0) {
        const e = this.bannerContent
          , s = window.innerWidth < 768 ? e.image_mobile : e.image
          , i = window.innerWidth < 768 ? e.text_mobile : e.text
          , r = document.createElement("img");
        r.src = s;
        const n = document.createElement("a");
        n.href = e.link;
        const o = document.createElement("a");
        o.href = e.link_mobile;
        const l = window.innerWidth < 768 ? o : n;
        w.selectPromotion(e.type, r.src, i, l.href, t)
    }
    setDataLayerEvents() {
        this.viewPromotionEvent(),
        this.addEventListener("pointerdown", () => {
            this.selectPromotionEvent()
        }
        )
    }
}
class Rt extends HTMLElement {
    constructor() {
        super()
    }
    connectedCallback() {
        this.faqItems = this.querySelectorAll(".faq-item"),
        this.init()
    }
    init() {
        var t;
        (t = this.faqItems) == null || t.forEach(e => {
            const s = e.querySelector(".faq-question");
            s && s.addEventListener("click", () => this.toggleFAQ(e))
        }
        )
    }
    toggleFAQ(t) {
        t.classList.toggle("active");
        const e = t.querySelector(".faq-answer");
        e && (e.style.display = t.classList.contains("active") ? "block" : "none"),
        this.closeOtherFAQs(t)
    }
    closeOtherFAQs(t) {
        var e;
        (e = this.faqItems) == null || e.forEach(s => {
            if (s !== t && s.classList.contains("active")) {
                s.classList.remove("active");
                const i = s.querySelector(".faq-answer");
                i && (i.style.display = "none")
            }
        }
        )
    }
}
class Ut extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 4,
                loop: !1,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 1024px)": {
                slidesToShow: 4,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 2,
                slidesToScroll: 2,
                loop: !0
            },
            "(max-width: 767px)": {
                slidesToShow: 1,
                slidesToScroll: 1,
                loop: !0
            }
        }),
        this.sliderElement
    }
}
class Qt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.querySelector(".blaze-slider"),
        this.leftContainer = this.querySelector(".mosaic__left-container"),
        this.rightContainer = this.querySelector(".mosaic__right-container"),
        this.setupSlider(),
        window.addEventListener("load", this.handleResize.bind(this))
    }
    setupSlider() {
        window.innerWidth < 768 && this.sliderElement && (new h(this.sliderElement,{
            all: {
                loop: !0,
                draggable: !0,
                transitionDuration: 400
            },
            "(max-width: 767px)": {
                slidesToShow: 1,
                slidesToScroll: 1,
                slideGap: "10px"
            }
        }),
        this.unwrapContainers())
    }
    handleResize() {
        window.innerWidth < 768 && this.unwrapContainers()
    }
    unwrapContainers() {
        var t, e;
        if (this.leftContainer) {
            for (; this.leftContainer.firstChild; )
                (t = this.leftContainer.parentNode) == null || t.insertBefore(this.leftContainer.firstChild, this.leftContainer);
            this.leftContainer.remove(),
            this.leftContainer = null
        }
        if (this.rightContainer) {
            for (; this.rightContainer.firstChild; )
                (e = this.rightContainer.parentNode) == null || e.insertBefore(this.rightContainer.firstChild, this.rightContainer);
            this.rightContainer.remove(),
            this.rightContainer = null
        }
    }
}
let Jt = class extends HTMLElement {
    constructor() {
        super(),
        this.setupSlider()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider");
        this.slider = new h(t,{
            all: {
                slidesToShow: 1,
                draggable: !0,
                slidesToScroll: 1,
                loop: !0,
                enablePagination: !0
            },
            "(min-width: 768px)": {
                slidesToShow: 2
            },
            "(min-width: 1200px)": {
                slidesToShow: 3
            }
        })
    }
}
;
class Yt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.querySelector(".blaze-slider"),
        this.setupSlider(),
        this.setupToggleBlocks()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 4,
                loop: !0,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 1366px)": {
                slidesToShow: 4,
                slidesToScroll: 1
            },
            "(max-width: 1100px)": {
                slidesToShow: 3,
                slidesToScroll: 1
            },
            "(max-width: 1024px)": {
                slidesToShow: 3,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 2,
                slidesToScroll: 1
            },
            "(max-width: 500px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        })
    }
    setupToggleBlocks() {
        const t = this.querySelectorAll(".toggle-block")
          , e = this.querySelectorAll("carousel-with-background");
        t.forEach(s => {
            s.addEventListener("click", i => {
                i.preventDefault();
                const r = (s.textContent || "").trim().toLowerCase();
                e.forEach(n => {
                    var c;
                    const o = n;
                    (((c = o.querySelector("h2")) == null ? void 0 : c.textContent) || "").trim().toLowerCase().includes(r) ? o.style.display = "block" : o.style.display = "none"
                }
                )
            }
            )
        }
        )
    }
}
class Kt extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 1,
                loop: !0,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 1120px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            },
            "(max-width: 1024px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
}
class Xt extends HTMLElement {
    constructor() {
        var t, e;
        super(),
        this.successMessage = null,
        this.errorMessage = null,
        this.productGiftWithPurchase = null,
        this.fetchData = new y,
        this.giftWithPurchaseInCart = !1,
        this.threshold = 0,
        this.amoutLeftMessage = this.formatHTMLCharCode(this.getAttribute("data-amount-left-message") || ""),
        this.threshold = parseFloat(this.getAttribute("data-gift-threshold") || "0"),
        this.productGiftWithPurchase = document.querySelector("[data-product-with-purchase]"),
        this.giftWithPurchaseProductId = ((t = this.productGiftWithPurchase) == null ? void 0 : t.getAttribute("data-product-with-purchase")) || "",
        this.giftWithPurchaseDiscountCode = ((e = this.productGiftWithPurchase) == null ? void 0 : e.getAttribute("data-product-discount-code")) || ""
    }
    connectedCallback() {
        this.getMessageElements()
    }
    getMessageElements() {
        this.successMessage = this.querySelector(".gift-with-purchase__success"),
        this.errorMessage = this.querySelector(".gift-with-purchase__error")
    }
    async updateMessage(t) {
        var s, i, r, n;
        document.querySelector(`[data-cart-product-variant-id="${this.giftWithPurchaseProductId}"]`);
        let e = this.threshold - t;
        e <= 0 ? ((s = this.errorMessage) == null || s.setAttribute("style", "display: none"),
        (i = this.successMessage) == null || i.setAttribute("style", "display: block")) : (this.giftWithPurchaseInCart = !1,
        (r = this.errorMessage) == null || r.setAttribute("style", "display: block"),
        (n = this.successMessage) == null || n.setAttribute("style", "display: none"),
        this.errorMessage && (this.errorMessage.innerHTML = this.amoutLeftMessage.replace(":::", e.toFixed(2))),
        localStorage.removeItem("gwpRemoved"))
    }
    formatHTMLCharCode(t) {
        return t.replace(/&#39;/g, "'").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    }
}
class Zt extends HTMLElement {
    constructor() {
        super(),
        this.setupSlider()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider");
        this.slider = new h(t,{
            all: {
                slidesToShow: 1.4,
                draggable: !0,
                slidesToScroll: 1,
                loop: !1
            },
            "(min-width: 768px)": {
                slidesToShow: 3
            },
            "(min-width: 1240px)": {
                slidesToShow: 5
            }
        })
    }
}
class te extends HTMLElement {
    constructor() {
        super(),
        this.setupSlider()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider");
        this.slider = new h(t,{
            all: {
                slidesToShow: 1.4,
                draggable: !0,
                slidesToScroll: 1,
                loop: !1
            },
            "(min-width: 769px)": {
                slidesToShow: 4
            },
            "(min-width: 1240px)": {
                slidesToShow: 4
            },
            "(max-width: 768px)": {
                slidesToShow: 1
            }
        })
    }
}
class ee extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.querySelector(".blaze-slider"),
        this.samples = this.querySelectorAll("[data-free-sample]"),
        this.sampleTitle = this.querySelector(".free_sample__title"),
        this.fetchData = new y,
        this.sampleLoader = this.querySelector("#freeSampleLoader"),
        this.updateFreeSamplesDisplay(),
        this.setupSlider(),
        this.setupEventListeners()
    }
    setupEventListeners() {
        var t;
        (t = this.samples) == null || t.forEach(e => {
            var s;
            (s = e.querySelector("button")) == null || s.addEventListener("click", () => this.handleSampleClick(e))
        }
        )
    }
    async handleSampleClick(t) {
        var e, s;
        !t.dataset.freeSample || !this.sampleTitle || ((e = this.sampleLoader) == null || e.classList.add("active"),
        await this.fetchData.addToCart("", t.dataset.freeSample, 1),
        await ((s = E.cartPage) == null ? void 0 : s.refreshCart()),
        await this.updateFreeSamplesDisplay())
    }
    verifyCartEmpty(t) {
        if (t.item_count === 0)
            return this.style.display = "none";
        this.style.display = "block"
    }
    async freeSamplesInCart(t) {
        return t.items.filter(s => s.product_type === "Free Sample").length
    }
    updateTitle(t) {
        var s, i;
        const e = (i = (s = this.sampleTitle) == null ? void 0 : s.textContent) == null ? void 0 : i.replace(/\(\d\/2\)/, `(${t}/2)`);
        this.sampleTitle && e && (this.sampleTitle.innerHTML = e)
    }
    disabledFreeSamples() {
        var t;
        this.setAttribute("disabled", ""),
        (t = this.samples) == null || t.forEach(e => {
            var s;
            (s = e.querySelector("button")) == null || s.setAttribute("disabled", "true")
        }
        )
    }
    enableFreeSamples() {
        var t;
        this.removeAttribute("disabled"),
        (t = this.samples) == null || t.forEach(e => {
            var s;
            (s = e.querySelector("button")) == null || s.removeAttribute("disabled")
        }
        )
    }
    async updateFreeSamplesDisplay() {
        var t;
        try {
            const e = await this.fetchData.getCart()
              , s = await this.freeSamplesInCart(e);
            this.verifyCartEmpty(e),
            this.updateTitle(s),
            s === 2 ? this.disabledFreeSamples() : this.enableFreeSamples(),
            (t = this.sampleLoader) == null || t.classList.remove("active")
        } catch (e) {
            console.error("Error updating free samples display: ", e)
        }
    }
    setupSlider() {
        if (!this.sliderElement)
            return;
        const t = {
            slidesToShow: this.classList.contains("cart-drawer__free-samples") ? 1.5 : 2,
            loop: !1,
            transitionDuration: 400,
            draggable: !0,
            slideGap: this.classList.contains("cart-drawer__free-samples") ? "12px" : "67px"
        };
        return new h(this.sliderElement,{
            all: t
        }),
        this.sliderElement
    }
}
class se extends HTMLElement {
    constructor() {
        super(),
        this.currentStep = 1,
        this.btnNextStep = this.querySelector(".next_step"),
        this.allNewsletterNavs = this.querySelectorAll(".newsletter_nav .newsletter_icon"),
        this.newsletterSteps = this.querySelectorAll(".newsletter_step"),
        this.form = this.querySelector("form"),
        this.notNowButton = this.querySelector(".btn_not_now"),
        this.phoneTermsCheckbox = this.querySelector('input[name="marketing_msgs"]'),
        this.phoneInput = this.querySelector('input[name="phone_number"]'),
        this.emailInput = this.querySelector('input[type="email"]'),
        this.submitButton = this.querySelector("button[data-formSubmit]"),
        this.phoneSubmit = this.querySelector("button[data-subscribe-phoneNumber]"),
        this.formSubmit = this.querySelector("[data-formSubmit]")
    }
    init() {
        this.addEventListeners(),
        this.setActiveStep(this.currentStep)
    }
    connectedCallback() {
        this.init()
    }
    addEventListeners() {
        this.allNewsletterNavs.forEach(t => {
            t.addEventListener("click", e => this.changeStep(e))
        }
        ),
        this.btnNextStep && this.btnNextStep.addEventListener("click", t => this.nextStep(t)),
        this.phoneSubmit && this.phoneSubmit.addEventListener("click", t => this.handleSubmit(t)),
        this.notNowButton && this.notNowButton.addEventListener("click", t => this.handleNotNow(t)),
        this.form && this.form.addEventListener("submit", async t => {
            this.handleSubmit(t),
            await fetch("https://manage.kmail-lists.com/ajax/subscriptions/subscribe", {
                method: "POST",
                body: new FormData(this.form)
            })
        }
        ),
        this.emailInput && this.emailInput.addEventListener("input", () => this.validateEmail())
    }
    changeStep(t) {
        const s = t.currentTarget.getAttribute("data-newsletter-step");
        if (s) {
            const i = parseInt(s);
            if (i > this.currentStep && !this.validateCurrentStep()) {
                t.preventDefault();
                return
            }
            this.setActiveStep(i)
        }
    }
    nextStep(t) {
        t.preventDefault(),
        this.validateCurrentStep() && this.setActiveStep(this.currentStep + 1)
    }
    setActiveStep(t) {
        this.currentStep = t,
        this.newsletterSteps.forEach( (e, s) => {
            s + 1 === t ? e.setAttribute("active", "") : e.removeAttribute("active")
        }
        ),
        this.allNewsletterNavs.forEach(e => {
            e.getAttribute("data-newsletter-step") === String(t) ? e.setAttribute("active", "") : e.removeAttribute("active")
        }
        )
    }
    validateCurrentStep() {
        const e = this.newsletterSteps[this.currentStep - 1].querySelectorAll("input");
        let s = !0;
        return e.forEach(i => {
            i.hasAttribute("required") && !i.value ? (s = !1,
            this.showError(i, "Este campo é obrigatório")) : i.type === "email" && !this.validateEmail() && (s = !1)
        }
        ),
        s
    }
    validateEmail() {
        if (!this.emailInput)
            return !1;
        const t = this.emailInput.value
          , s = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(t);
        return s ? this.removeError(this.emailInput) : this.showError(this.emailInput, "Por favor, insira um endereço de e-mail válido"),
        s
    }
    showError(t, e) {
        this.removeError(t);
        const s = this.querySelector(".newsletter_field_error");
        s.className = "error-message",
        s.textContent = e
    }
    removeError(t) {
        const e = t.nextElementSibling;
        e && e.classList.contains("error-message") && e.remove()
    }
    handleSubmit(t) {
        var e;
        t.preventDefault(),
        this.validateCurrentStep() && (this.setActiveStep(3),
        (e = this.submitButton) == null || e.click())
    }
    handleNotNow(t) {
        this.phoneTermsCheckbox && (this.phoneTermsCheckbox.checked = !1),
        this.phoneInput && (this.phoneInput.value = ""),
        this.handleSubmit(t)
    }
}
class ie extends HTMLElement {
    constructor() {
        super(),
        this.linkSe = null,
        this.linkMo = null,
        this.formMyOwn = null,
        this.formSomeoneElse = null,
        this.textLinkMyOwn = null,
        this.textLinkSomeoneElse = null
    }
    connectedCallback() {
        this.getElements(),
        this.setEventListeners()
    }
    getElements() {
        this.linkSe = document.querySelector(".Link-Se"),
        this.linkMo = document.querySelector(".Link-Mo"),
        this.formMyOwn = document.querySelector(".MyOwnForm"),
        this.formSomeoneElse = document.querySelector(".SomeoneElseForm"),
        this.textLinkMyOwn = document.querySelector(".linkMyOwn"),
        this.textLinkSomeoneElse = document.querySelector(".linkSomeoneElse")
    }
    setEventListeners() {
        this.linkSe && this.linkSe.addEventListener("click", this.showSomeoneElseForm.bind(this)),
        this.linkMo && this.linkMo.addEventListener("click", this.showMyOwnForm.bind(this))
    }
    showMyOwnForm() {
        this.formMyOwn && this.formMyOwn.classList.add("visible"),
        this.formSomeoneElse && this.formSomeoneElse.classList.remove("visible"),
        this.textLinkMyOwn && this.textLinkMyOwn.classList.remove("hidden"),
        this.textLinkSomeoneElse && this.textLinkSomeoneElse.classList.add("hidden")
    }
    showSomeoneElseForm() {
        this.formMyOwn && this.formMyOwn.classList.remove("visible"),
        this.formMyOwn && this.formMyOwn.classList.add("hidden"),
        this.formSomeoneElse && this.formSomeoneElse.classList.add("visible"),
        this.textLinkMyOwn && this.textLinkMyOwn.classList.add("hidden"),
        this.textLinkMyOwn && this.textLinkMyOwn.classList.remove("visible"),
        this.textLinkSomeoneElse && this.textLinkSomeoneElse.classList.remove("hidden")
    }
}
class re extends HTMLElement {
    constructor() {
        super(),
        this.checkbox = this.querySelector("#gift-wrap-checkbox"),
        this.giftWrapForm = this.querySelector("#gift-wrap-form"),
        this.productGiftWrap = this.getAttribute("data-gift-wrap-product") || "",
        this.productVariantGiftWrap = this.getAttribute("data-gift-wrap-variant") || "",
        this.fetchData = new y,
        this.setup()
    }
    setup() {
        this.checkbox.addEventListener("change", this.checkboxChange.bind(this))
    }
    async checkboxChange(t) {
        var s;
        t.target.checked ? (await this.fetchData.addToCart(this.productGiftWrap, this.productVariantGiftWrap),
        await ((s = E.cartPage) == null ? void 0 : s.refreshCart())) : (await this.fetchData.removeFromCart(this.productVariantGiftWrap),
        setTimeout(async () => {
            var i;
            await ((i = E.cartPage) == null ? void 0 : i.refreshCart())
        }
        , 1e3))
    }
}
class ne extends HTMLElement {
    constructor() {
        super(),
        this.slider = null,
        this.slidesTriggered = [],
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider"),
        this.slider = new h(this.sliderElement,{
            all: {
                enableAutoplay: !0,
                autoplayInterval: 1e4,
                transitionDuration: 800,
                slidesToShow: 1,
                draggable: !0,
                stopAutoplayOnInteraction: !1
            }
        }),
        this.sliderElement
    }
}
class ae extends HTMLElement {
    constructor() {
        super(),
        this.setupSlider()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider");
        this.slider = new h(t,{
            all: {
                slidesToShow: 1,
                draggable: !0
            }
        })
    }
}
class oe extends HTMLElement {
    constructor() {
        super(),
        this.sliderElement = this.setupSlider()
    }
    setupSlider() {
        return this.sliderElement = this.querySelector(".blaze-slider-htj-slider"),
        new h(this.sliderElement,{
            all: {
                slidesToShow: 3,
                loop: !0,
                transitionDuration: 400,
                draggable: !0
            },
            "(max-width: 550px)": {
                slidesToShow: 2,
                slidesToScroll: 1
            },
            "(max-width: 425px)": {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }),
        this.sliderElement
    }
}
class le extends HTMLElement {
    constructor() {
        super()
    }
}
class ce extends HTMLElement {
    constructor() {
        super(),
        this.form = this.querySelector("form"),
        this.emailInput = this.querySelector("#k_id_email"),
        this.birthDateInput = this.querySelector("#k_id_birth_date"),
        this.submitButton = this.querySelector("[data-subscribeFormButton]"),
        this.formSubmitButton = this.querySelector("[data-formSubmit]"),
        this.init()
    }
    init() {
        this.addEventListeners(),
        this.initializeKlaviyo()
    }
    addEventListeners() {
        this.submitButton && this.submitButton.addEventListener("click", t => this.handleSubmit(t)),
        this.emailInput && this.emailInput.addEventListener("input", () => this.validateEmail()),
        this.birthDateInput && (this.birthDateInput.addEventListener("input", t => this.formatBirthDate(t)),
        this.birthDateInput.addEventListener("keydown", t => this.handleBackspace(t))),
        this.form && this.form.addEventListener("submit", t => {
            t.preventDefault(),
            this.handleSubmit(t)
        }
        )
    }
    validateEmail() {
        if (!this.emailInput)
            return !1;
        const t = this.emailInput.value
          , s = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(t);
        return s ? this.removeError(this.emailInput) : this.showError(this.emailInput, "Please enter a valid email address"),
        s
    }
    formatBirthDate(t) {
        const e = t.target;
        let s = e.value.replace(/\D/g, "");
        s.length > 6 && (s = s.slice(0, 6)),
        s.length > 4 ? s = `${s.slice(0, 2)}/${s.slice(2, 4)}/${s.slice(4)}` : s.length > 2 && (s = `${s.slice(0, 2)}/${s.slice(2)}`),
        e.value = s
    }
    handleBackspace(t) {
        const e = t.target;
        t.key === "Backspace" && (e.value.endsWith("/") || e.value.length === 3 || e.value.length === 6) && (e.value = e.value.slice(0, -1))
    }
    validateBirthDate() {
        if (!this.birthDateInput)
            return !1;
        const t = this.birthDateInput.value
          , s = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{2}$/.test(t);
        return s ? this.removeError(this.birthDateInput) : this.showError(this.birthDateInput, "Please enter a valid date in MM/DD/YY format"),
        s
    }
    showError(t, e) {
        var i;
        this.removeError(t);
        const s = document.createElement("div");
        s.className = "error-message",
        s.textContent = e,
        (i = t.parentNode) == null || i.insertBefore(s, t.nextSibling)
    }
    removeError(t) {
        const e = t.nextElementSibling;
        e && e.classList.contains("error-message") && e.remove()
    }
    handleSubmit(t) {
        var e;
        t.preventDefault(),
        this.validateEmail() && this.validateBirthDate() ? ((e = this.formSubmitButton) == null || e.click(),
        this.submitToKlaviyo()) : console.error("Validation failed.")
    }
    initializeKlaviyo() {
        KlaviyoSubscribe.bootstrap(),
        this.emailInput && KlaviyoSubscribe.watchField("[data-mailId]", "$email", {
            type: "email"
        }),
        this.birthDateInput && KlaviyoSubscribe.watchField("[data-birthDay]", "birth_date", {
            type: "text"
        })
    }
    submitToKlaviyo() {
        if (this.form) {
            const t = this.form.querySelector('input[name="g"]');
            if (t instanceof HTMLInputElement) {
                const e = t.value;
                this.setAttribute("data-subscribed", "");
                try {
                    KlaviyoSubscribe.attachToForms(this.form, {
                        list: e,
                        success: (s, i) => {
                            var n;
                            const r = (n = this.form) == null ? void 0 : n.getAttribute("data-subscribedmsg");
                            r && alert(r)
                        }
                        ,
                        error: (s, i) => {
                            console.error("Subscription error", i),
                            alert("An error occurred. Please try again.")
                        }
                    })
                } catch (s) {
                    console.error("Error submitting to Klaviyo:", s),
                    alert("An unexpected error occurred. Please try again later.")
                }
            } else
                console.error("List input not found or not an HTMLInputElement.")
        } else
            console.error("Form not found.")
    }
}
class de extends HTMLElement {
    constructor() {
        super(),
        this.amountForFreeShippingMessage = "",
        this.freeShippingMessage = "",
        this.messageElement = null,
        this.progressBar = null,
        this.threshold = 1
    }
    connectedCallback() {
        this.getMessages(),
        this.getElements(),
        this.getThreshold()
    }
    getThreshold() {
        const t = this.getAttribute("data-free-shipping-threshold");
        t && (this.threshold = parseFloat(t == "0" ? "1" : t))
    }
    getMessages() {
        const t = this.getAttribute("data-amount-for-free-shipping-message") || "";
        this.amountForFreeShippingMessage = this.formatHTMLCharCode(t);
        const e = this.getAttribute("data-free-shipping-message") || "";
        this.freeShippingMessage = this.formatHTMLCharCode(e)
    }
    formatHTMLCharCode(t) {
        return t.replace(/&#39;/g, "'").replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    }
    getElements() {
        this.messageElement = this.querySelector("#message__shipping-free"),
        this.progressBar = this.querySelector(".shipping-free__progress-bar")
    }
    updateProgressBar(t) {
        var i;
        const e = this.threshold - t;
        e > 0 ? this.messageElement && (this.messageElement.innerHTML = this.amountForFreeShippingMessage.replace(":::", e.toFixed(2))) : this.messageElement && (this.messageElement.innerHTML = this.freeShippingMessage);
        const s = t / this.threshold * 100;
        (i = this.progressBar) == null || i.setAttribute("style", `width: ${s}%`)
    }
}
class ue extends HTMLElement {
    constructor() {
        super(),
        this.input = null,
        this.modal = null,
        this.modalCloseButton = null,
        this.modalCloseButtonInner = null,
        this.modalSaveButton = null,
        this.modalTextArea = null,
        this.form = null,
        this.giftVariantId = null,
        this.giftProductId = null,
        this.giftOnCart = !1,
        this.debounceTime = 250,
        this.fetchData = new y
    }
    connectedCallback() {
        this.getElements(),
        this.getInitialData(),
        this.setup()
    }
    getInitialData() {
        var t;
        this.giftVariantId = this.getAttribute("data-gift-wrap-variant") || null,
        this.giftProductId = this.getAttribute("data-gift-wrap-product") || null,
        this.giftOnCart = !!((t = this.input) != null && t.getAttribute("checked"))
    }
    getElements() {
        this.input = this.querySelector("#GiftWrapCartDrawerInput"),
        this.modal = this.querySelector("#GiftWrapCartDrawerModal"),
        this.modalCloseButton = this.querySelector("#GiftWrapCartDrawerModalClose"),
        this.modalCloseButtonInner = this.querySelector("#GiftWrapCartDrawerModalCloseInner"),
        this.modalSaveButton = this.querySelector("#GiftWrapCartDrawerModalSave"),
        this.modalTextArea = this.querySelector("#GiftWrapCartDrawerMessageTextArea"),
        this.form = this.querySelector("#GiftWrapCartDrawerForm")
    }
    setup() {
        var t, e, s, i;
        (t = this.input) == null || t.addEventListener("change", this.handleInput.bind(this)),
        (e = this.modalCloseButton) == null || e.addEventListener("click", this.handleModalClose.bind(this)),
        (s = this.modalCloseButtonInner) == null || s.addEventListener("click", this.handleModalClose.bind(this)),
        (i = this.modalSaveButton) == null || i.addEventListener("click", this.handleModalSave.bind(this))
    }
    async handleInput() {
        var t;
        (t = this.input) != null && t.checked ? (this.openModal(),
        this.debounceFunction(async () => {
            await this.addGiftWrap()
        }
        )) : (this.debounceFunction(async () => {
            await this.removeGiftWrap(),
            await this.fetchData.addNoteToCart(" ")
        }
        ),
        this.modalTextArea && (this.modalTextArea.value = ""),
        this.modalTextArea && (this.modalTextArea.textContent = ""))
    }
    handleModalClose(t) {
        t.preventDefault(),
        this.closeModal()
    }
    async handleModalSave(t) {
        t.preventDefault(),
        this.modalTextArea && await this.fetchData.addNoteToCart(this.modalTextArea.value),
        this.closeModal()
    }
    openModal() {
        this.modal && (this.modal.style.display = "")
    }
    closeModal() {
        this.modal && (this.modal.style.display = "none")
    }
    async addGiftWrap() {
        !this.giftVariantId || !this.giftProductId || await this.fetchData.addToCart(this.giftProductId, this.giftVariantId)
    }
    async removeGiftWrap() {
        this.giftVariantId && await this.fetchData.removeFromCart(this.giftVariantId)
    }
    debounceFunction(t) {
        this.debounce && (clearTimeout(this.debounce),
        this.debounce = void 0),
        this.debounce = setTimeout(t, this.debounceTime)
    }
}
class he extends HTMLElement {
    constructor() {
        var t, e;
        super(),
        this.shopifyAccessToken = "e6aa537b278bf7b6cc14453c9d91a5f2",
        this.shopifyUrl = "https://natura-br.myshopify.com",
        this.couponHeading = this.querySelector(".discount_coupon__heading"),
        this.form = this.querySelector(".discount_coupon__form"),
        this.input = this.querySelector(".discount_coupon__input"),
        this.applyButton = this.querySelector(".discount_coupon__button"),
        this.errorMessage = this.querySelector(".discount_coupon__error"),
        this.successMessage = this.querySelector(".discount_coupon__success"),
        this.appliedCouponsContainer = this.querySelector(".discount_coupon__applied"),
        this.cartDrawerElement = document.querySelector("cart-drawer"),
        this.FetchData = new y,
        this.CartPage = document.querySelector("cart-page"),
        (t = this.couponHeading) == null || t.addEventListener("click", s => {
            document.querySelector("cart-drawer .shipping__form")
        }
        ),
        (e = this.form) == null || e.addEventListener("submit", this.handleSubmit.bind(this)),
        this.initializeAppliedCoupons()
    }
    async handleSubmit(t) {
        if (t.preventDefault(),
        !this.input || !this.applyButton)
            return;
        const e = this.input.value.trim();
        e && (this.applyButton.disabled = !0,
        await this.applyCoupon(e),
        this.applyButton.disabled = !1)
    }
    async applyCoupon(t) {
        try {
            const e = await this.getCart()
              , i = [...this.getAppliedCoupons(), t]
              , r = await this.updateCartDiscountCodes(e.token, i);
            this.isCouponApplicable(r) ? (this.showSuccessMessage("Coupon applied successfully"),
            this.input.value = "",
            this.updateAppliedCoupons(i),
            this.updateCartDisplay(r)) : this.showErrorMessage("Invalid coupon")
        } catch (e) {
            console.error("Error applying coupon:", e),
            this.showErrorMessage("Error applying coupon")
        }
    }
    async removeCoupon(t) {
        var e, s;
        try {
            const i = await this.getCart()
              , r = this.getAppliedCoupons().filter(o => o !== t)
              , n = await this.updateCartDiscountCodes(i.token, r);
            this.showSuccessMessage("Coupon removed successfully"),
            (s = (e = this.querySelector(`[data-coupon="${t}"]`)) == null ? void 0 : e.parentElement) == null || s.remove(),
            this.updateCartDisplay(n)
        } catch (i) {
            console.error("Error applying coupon:", i),
            this.showErrorMessage("Error applying coupon")
        }
    }
    async updateCartDiscountCodes(t, e) {
        const s = `${this.shopifyUrl}/api/2024-04/graphql.json`
          , i = {
            query: `
                mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
                    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
                        cart {
                            discountCodes {
                                code
                                applicable
                            }
                            cost {
                                subtotalAmount {
                                    amount
                                    currencyCode
                                }
                                totalAmount {
                                    amount
                                    currencyCode
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                cartId: `gid://shopify/Cart/${t}`,
                discountCodes: e
            }
        };
        try {
            const r = await fetch(s, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Shopify-Storefront-Access-Token": this.shopifyAccessToken
                },
                body: JSON.stringify(i)
            });
            if (!r.ok) {
                const o = await r.text();
                throw console.error("API Response:", r.status, o),
                new Error(`Shopify API Response Error: ${r.status} ${r.statusText}`)
            }
            const n = await r.json();
            if (n.errors)
                throw console.error("GraphQL Errors:", n.errors),
                new Error(`Error GraphQL: ${n.errors[0].message}`);
            return n
        } catch (r) {
            throw console.error("Fetch error:", r),
            r
        }
    }
    async getCart() {
        return await (await fetch("/cart.js")).json()
    }
    getAppliedCoupons() {
        var t;
        return Array.from(((t = this.appliedCouponsContainer) == null ? void 0 : t.querySelectorAll(".applied_coupon")) || []).map(e => {
            var s;
            return ((s = e.textContent) == null ? void 0 : s.trim()) || ""
        }
        )
    }
    updateAppliedCoupons(t) {
        this.appliedCouponsContainer && (this.appliedCouponsContainer.innerHTML = t.map(e => `
            <div class="applied_coupon">
                ${e}
                <button class="remove-coupon" data-coupon="${e}">Remove</button>
            </div>
        `).join(""),
        this.appliedCouponsContainer.querySelectorAll(".remove-coupon").forEach(e => {
            e.addEventListener("click", s => {
                const i = s.currentTarget.dataset.coupon;
                i && this.removeCoupon(i)
            }
            )
        }
        ))
    }
    async updateCartDisplay(t) {
        var i;
        const e = await this.FetchData.getCart();
        (i = this.CartPage) == null || i.refreshCart(),
        f.updateCart(e);
        const s = new CustomEvent("cart:updated",{
            detail: t
        });
        document.dispatchEvent(s)
    }
    isCouponApplicable(t) {
        return t.data.cartDiscountCodesUpdate.cart.discountCodes.every(e => e.applicable)
    }
    showErrorMessage(t) {
        this.errorMessage && (this.errorMessage.textContent = t,
        this.errorMessage.style.display = "block",
        setTimeout( () => {
            this.errorMessage.style.display = "none"
        }
        , 3e3))
    }
    showSuccessMessage(t) {
        this.successMessage && (this.successMessage.textContent = t,
        this.successMessage.style.display = "block",
        setTimeout( () => {
            this.successMessage.style.display = "none"
        }
        , 3e3))
    }
    initializeAppliedCoupons() {
        this.getCart().then(t => {
            console.log(t),
            t.cart_level_discount_applications && this.updateAppliedCoupons(t.cart_level_discount_applications.map(e => e.title)),
            t.items.map(e => {
                e.discounts && e.discounts.map(s => {
                    this.updateAppliedCoupons(s.title ? [s.title] : [])
                }
                )
            }
            )
        }
        )
    }
}
class pe extends HTMLElement {
    constructor() {
        var t;
        super(),
        this.slides = (t = this.querySelector(".blaze-track.zoom-blaze")) == null ? void 0 : t.children,
        this.mainSlide = this.querySelector(".modal__image img"),
        this.prevButton = this.querySelector(".blaze-prev.zoom-prev"),
        this.nextButton = this.querySelector(".blaze-next.zoom-next"),
        this.setup()
    }
    setup() {
        this.setupSlider()
    }
    setupSlider() {
        const t = this.querySelector(".blaze-slider.zoom-slider")
          , e = new h(t,{
            all: {
                enableAutoplay: !1,
                loop: !0,
                transitionDuration: 300,
                slidesToShow: 4,
                slidesToScroll: 1
            },
            "(max-width: 768px)": {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        });
        this.setupOnSlide(e)
    }
    updateSlidePosition(t, e) {
        t.forEach( (s, i) => {
            s.style.display = i === e ? "block" : "none"
        }
        )
    }
    setupOnSlide(t) {
        console.log("slides", this.slides);
        let e = 0;
        if (this.slides.length === 0)
            return;
        this.slides[0].classList.add("active");
        const s = Array.from(this.slides)
          , i = r => {
            var n, o;
            s.forEach(l => l.classList.remove("active")),
            s[r].classList.add("active"),
            this.mainSlide.src = ((n = s[r].querySelector("img")) == null ? void 0 : n.src) || "",
            this.mainSlide.alt = ((o = s[r].querySelector("img")) == null ? void 0 : o.alt) || ""
        }
        ;
        s.forEach( (r, n) => {
            r.addEventListener("click", () => {
                const o = n - t.stateIndex;
                o > 0 && t.next(o),
                o < 0 && t.prev(o * -1),
                e = n,
                i(e)
            }
            )
        }
        ),
        this.nextButton.addEventListener("click", () => {
            e = (e + 1) % this.slides.length,
            t.next(1),
            i(e)
        }
        ),
        this.prevButton.addEventListener("click", () => {
            e = (e - 1 + this.slides.length) % this.slides.length,
            t.prev(1),
            i(e)
        }
        )
    }
}
window.customElements.define("carousel-with-background", Yt);
window.customElements.define("header-component", K);
window.customElements.define("banner-slider", pt);
window.customElements.define("infinite-bar", X);
window.customElements.define("collection-list", mt);
window.customElements.define("collection-section", yt);
window.customElements.define("product-card", gt);
window.customElements.define("look-book", St);
window.customElements.define("news-slider", wt);
window.customElements.define("steps-slider", vt);
window.customElements.define("newsletter-section", bt);
window.customElements.define("announcement-bar", _t);
window.customElements.define("benefits-section", Et);
window.customElements.define("benefits-caroussel", Ct);
window.customElements.define("related-products", xt);
window.customElements.define("product-card-js", J);
window.customElements.define("facet-filter", Lt);
window.customElements.define("collection-grid", Tt);
window.customElements.define("explore-our-collections", qt);
window.customElements.define("collection-featured-product", It);
window.customElements.define("offers-card", Pt);
window.customElements.define("cart-drawer", f);
window.customElements.define("cart-related-product-card", j);
window.customElements.define("cart-product-item", kt);
window.customElements.define("benefits-description", Mt);
window.customElements.define("account-access-component", At);
window.customElements.define("main-product", Dt);
window.customElements.define("carousel-certificates", Bt);
window.customElements.define("benefits-section-wschema", Ot);
window.customElements.define("benefits-affiliate-carousel", Ht);
window.customElements.define("main-offer", Ft);
window.customElements.define("product-image-slider", $t);
window.customElements.define("client-menu", Nt);
window.customElements.define("product-details-tabs", zt);
window.customElements.define("multiple-steps-sliders", Wt);
window.customElements.define("reviews-component", jt);
window.customElements.define("styled-scroll", Gt);
window.customElements.define("thin-banner", Vt);
window.customElements.define("faq-pdp", Rt);
window.customElements.define("cart-page", E);
window.customElements.define("images-slider", Jt);
window.customElements.define("cards-slide-step-by-step", Ut);
window.customElements.define("multi-image-mosaic", Qt);
window.customElements.define("banner-slide-with-text", Kt);
window.customElements.define("img-slider", Zt);
window.customElements.define("img-slider-btn", te);
window.customElements.define("free-samples", ee);
window.customElements.define("steps-newsletter", se);
window.customElements.define("custom-form", ie);
window.customElements.define("gift-wrap", re);
window.customElements.define("gift-with-purchase-cart-drawer", Xt);
window.customElements.define("institutional-slider", ne);
window.customElements.define("institutional-grid", ae);
window.customElements.define("how-to-join", oe);
window.customElements.define("banner-404", le);
window.customElements.define("discount-section", ce);
window.customElements.define("free-shipping-cart-drawer", de);
window.customElements.define("gift-wrap-cart-drawer", ue);
window.customElements.define("discount-coupon", he);
window.customElements.define("zoom-modal", pe);
